import {Routes,Route} from 'react-router-dom'
import Home from './Pages/Home'
import TermsCondition from './Pages/TermsCondition'
import Disclaimer from './Pages/Disclaimer';
import PrivacyPolicy from './Pages/PrivacyPolicy'
import Contact from './Pages/Contact'
import About from './Pages/About'
import AllTools from './Pages/AllTools';
import FdCalculator from  './Tools/FdCalculator'
import RdCalculator from  './Tools/RdCalculator'
import SukanyaSamriddhiCalculator from  './Tools/SukanyaSamriddhiCalculator'
import NscCalculator from  './Tools/NscCalculator'
import MisCalculator from  './Tools/MisCalculator'
import TdCalculator from  './Tools/TdCalculator'
import SeniorCitizenCalculator from  './Tools/SeniorCitizenCalculator'
import SipCalculator from  './Tools/SipCalculator'


import './App.css';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path="/terms-and-conditions" element={<TermsCondition />}></Route>
        <Route path="/disclaimer" element={<Disclaimer />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="/contact-us" element={<Contact />}></Route>
        <Route path="/about-us" element={<About />}></Route>
        <Route path="/all-tools" element={<AllTools />}></Route>
        <Route path="/post-office-fd-calculator" element={<FdCalculator />}></Route>
        <Route path="/post-office-rd-calculator" element={<RdCalculator />}></Route>
        <Route path="/post-office-sukanya-samriddhi-calculator" element={<SukanyaSamriddhiCalculator />}></Route>
        <Route path="/post-office-nsc-calculator" element={<NscCalculator />}></Route>
        <Route path="/post-office-mis-calculator" element={<MisCalculator />}></Route>
        <Route path="/post-office-td-calculator" element={<TdCalculator />}></Route>
        <Route path="/post-office-scss-calculator" element={<SeniorCitizenCalculator />}></Route>
        <Route path="/post-office-sip-calculator" element={<SipCalculator />}></Route>

        

      </Routes>
    </>
  );
}

export default App;
