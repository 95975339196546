import React from 'react';
import Header from './Header'; // Importing Header component
import Footer from './Footer'; // Importing Footer component
import { Helmet } from 'react-helmet';

const Layout = ({pageTitle,  metaDescription, metaKeywords, schemaData ,ogTitle, ogDescription,ogUrl, ogImage,children }) => {
  return (
    <>
    <Helmet>
    <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />

        {/* Dynamic Schema.org data */}
        {schemaData && (
          <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
        )}

        {/* Open Graph data */}
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {ogDescription && <meta property="og:description" content={ogDescription} />}
        {ogUrl && <meta property="og:url" content={ogUrl} />}
        {ogImage && <meta property="og:image" content={ogImage} />}
    </Helmet>
      <Header/> {/* Rendering the Header component */}
      <main>
        {children} {/* Rendering the child components passed to Layout */}
      </main>
      <Footer/> {/* Rendering the Footer component */}
    </>
  );
};

export default Layout; // Exporting the Layout component as the default export