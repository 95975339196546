import React from 'react'
import { Link } from 'react-router-dom'
import "./Contact.css";



const Sidebar = () => {
  return (
    <div>
      <div className='ads'>
      </div>

      <div className='text'>
        <h3 className='text-center' style={{ color: 'black', paddingTop: '0.5rem' }}>Important Tools</h3>
      </div>
      
      <div className='tools-list'>
        <ul type='none'>
          <li><Link to='/post-office-mis-calculator' target="_blank" style={{ color: 'black', paddingTop: '0.5rem' }}>MIS Calculator</Link></li>
          <li><Link to='/post-office-fd-calculator' target="_blank" style={{ color: 'black', paddingTop: '0.5rem' }}>FD Calculator</Link></li>
          <li><Link to='/post-office-scss-calculator' target="_blank" style={{ color: 'black', paddingTop: '0.5rem' }}>Senior Citizen Saving Scheme</Link></li>
          <li><Link to='/post-office-sip-calculator' target="_blank" style={{ color: 'black', paddingTop: '0.5rem' }}>SIP Calculator</Link></li>
          <li><Link to='/post-office-nsc-calculator' target="_blank" style={{ color: 'black', paddingTop: '0.5rem' }}>NSC Calculator</Link></li>
          <li><Link to='/post-office-td-calculator' target="_blank" style={{ color: 'black', paddingTop: '0.5rem' }}>TD Calculator</Link></li>
          <li><Link to='/post-office-sukanya-samriddhi-calculator' target="_blank" style={{ color: 'black', paddingTop: '0.5rem' }}>Sukanya Samriddhi Yojana Calculator</Link></li>
          <li><Link to='/post-office-rd-calculator' target="_blank" style={{ color: 'black', paddingTop: '0.5rem' }}>RD Calculator</Link></li>
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
