import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Layout from '../Component/Layout';
import Sidebar from '../Pages/Sidebar';

const PostOfficeRDCalculator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [monthlyDeposit, setMonthlyDeposit] = useState('');
  const [interestRate, setInterestRate] = useState('5.8'); // Default interest rate
  const [investmentTerm, setInvestmentTerm] = useState('');
  const [calculationData, setCalculationData] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const monthly = parseFloat(monthlyDeposit);
    const rate = parseFloat(interestRate) / 100 / 4; // Quarterly compounding
    const term = parseInt(investmentTerm) * 12; // Convert years to months

    // RD maturity formula: M = P × [(1 + r/n)^(nt) - 1] × (1 + r/n) / (r/n)
    const maturityAmount =
      monthly * ((Math.pow(1 + rate, term / 3) - 1) * (1 + rate)) / rate;

    setCalculationData({
      maturityAmount: maturityAmount.toFixed(2),
      totalDeposit: (monthly * term).toFixed(2),
      interestEarned: (maturityAmount - monthly * term).toFixed(2),
    });
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([
      {
        'Monthly Deposit:': monthlyDeposit,
        'Total Deposit:': calculationData.totalDeposit,
        'Interest Earned:': calculationData.interestEarned,
        'Maturity Amount:': calculationData.maturityAmount,
      },
    ]);

    XLSX.utils.book_append_sheet(wb, ws, 'PostOfficeRDData');
    XLSX.writeFile(wb, 'Post_Office_RD_Calculator_Data.xlsx');
  };

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter.format(amount);
  };

  return (
    <Layout
    pageTitle="Post Office RD Calculator | PostOfficeCalculators"
    metaDescription="Calculate returns on your Post Office Recurring Deposit (RD) investment with our easy-to-use RD Calculator."
    metaKeywords="Post Office RD Calculator, RD returns, Post Office savings, investment calculator"
    canonicalUrl="https://postofficecalculators.com/post-office-rd-calculator"
    robotsContent="index, follow"
    ogTitle="Post Office RD Calculator | PostOfficeCalculators"
    ogDescription="Use our Post Office RD Calculator to calculate your returns from Recurring Deposit investments."
    ogUrl="https://postofficecalculators.com/post-office-rd-calculator"
    ogImage="https://postofficecalculators.com/img/logo.png"
    schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://postofficecalculators.com/post-office-rd-calculator",
        "name": "Post Office RD Calculator",
        "description": "Calculate your returns from Post Office Recurring Deposit investments with our easy-to-use calculator.",
        "publisher": {
            "@type": "Organization",
            "name": "PostOfficeCalculators",
            "logo": {
                "@type": "ImageObject",
                "url": "https://postofficecalculators.com/img/logo.png"
            }
        }
    }}
>

      <br />
      
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-8 main-section">
            <div className="container">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/all-tools" style={{ textDecoration: 'none', color: 'black' }}>All Tools</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Post Office RD Calculator</li>
                </ol>
              </nav>

              <form onSubmit={handleSubmit}>
                <h1 className="text-center">Post Office RD Calculator</h1>
                <p className="text-center">Estimate the maturity value of your Recurring Deposit.</p>
                <br />
                <div className="container text-center">
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="MonthlyDeposit" className="form-label label">
                        <b>Monthly Deposit (INR): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={monthlyDeposit}
                        className="form-control"
                        onChange={(e) => setMonthlyDeposit(e.target.value)}
                        placeholder="Enter Monthly Deposit"
                        required
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="InterestRate" className="form-label label">
                        <b>Interest Rate (Annual %): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={interestRate}
                        className="form-control"
                        onChange={(e) => setInterestRate(e.target.value)}
                        placeholder="Enter Interest Rate"
                        required
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="Term" className="form-label label">
                        <b>Investment Term (Years): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={investmentTerm}
                        className="form-control"
                        onChange={(e) => setInvestmentTerm(e.target.value)}
                        placeholder="Enter Investment Term"
                        required
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-md-12">
                      <button type="submit" className="btn btn-success">Calculate</button>
                    </div>
                  </div>
                </div>
              </form>

              {calculationData && (
                <div className="container">
                  <hr />
                  <h5>Summary :</h5>
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th scope="col">Total Deposit:</th>
                          <td>{formatCurrency(calculationData.totalDeposit)}</td>
                        </tr>
                        <tr>
                          <th scope="col">Interest Earned:</th>
                          <td>{formatCurrency(calculationData.interestEarned)}</td>
                        </tr>
                        <tr>
                          <th scope="col">Maturity Amount:</th>
                          <td>{formatCurrency(calculationData.maturityAmount)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button className="btn btn-success" onClick={exportToExcel}>Export to Excel</button>
                </div>
              )}
              <div className="container">
  <div className="row" style={{ marginTop: '50px' }}>
    <hr />
    <h3 className="text-center">About Post Office RD Calculator</h3>
    <p>
      The Post Office Recurring Deposit (RD) Calculator is a reliable tool designed to help users estimate the returns on their recurring deposit investments with the Indian Post Office. 
      This scheme is a government-backed savings option that allows individuals to deposit small amounts regularly, making it ideal for disciplined savers. 
      The calculator provides an accurate estimate of the maturity amount and the interest earned over the chosen tenure, assisting users in achieving their financial goals.
    </p>

    <h4>How the Calculator Works</h4>
    <p>
      The Post Office RD Calculator simplifies the complex calculations of interest compounding, allowing you to focus on your savings strategy. Based on the input values provided, the calculator estimates:
      <ul>
        <li><strong>Maturity Amount:</strong> The total amount you will receive at the end of the RD tenure, including all deposits and compounded interest.</li>
        <li><strong>Interest Earned:</strong> The total interest generated over the deposit period, helping you understand the growth of your investment.</li>
        <li><strong>Total Deposits:</strong> The sum of all monthly deposits made during the tenure.</li>
      </ul>
    </p>

    <h4>Why Choose Post Office RD?</h4>
    <ul>
      <li><strong>Government-Backed Security:</strong> Post Office RDs are among the safest savings options, backed by the Government of India.</li>
      <li><strong>Affordable Savings:</strong> Start with a minimum deposit of just ₹100 per month, making it accessible to everyone.</li>
      <li><strong>Quarterly Compounding:</strong> Interest is compounded quarterly, providing better returns over time.</li>
      <li><strong>Fixed Tenure:</strong> A fixed 5-year tenure ensures disciplined saving habits.</li>
      <li><strong>Nomination Facility:</strong> Assign a nominee to ensure smooth transfer of funds in case of unforeseen events.</li>
    </ul>

    <h4>Input Parameters</h4>
    <p>
      To provide accurate results, the Post Office RD Calculator requires the following inputs:
      <ul>
        <li><strong>Monthly Deposit Amount (₹):</strong> The amount you wish to deposit every month.</li>
        <li><strong>Tenure:</strong> A fixed tenure of 5 years.</li>
        <li><strong>Interest Rate:</strong> The current annual interest rate offered (updated as per government notifications).</li>
      </ul>
    </p>

    <h4>Features of the Post Office RD Calculator</h4>
    <ul>
      <li><strong>Simple and User-Friendly Interface:</strong> Easily calculate returns by entering a few details.</li>
      <li><strong>Real-Time Results:</strong> Instant calculations based on the latest interest rates.</li>
      <li><strong>Detailed Breakdown:</strong> View the maturity amount and interest earned over time.</li>
      <li><strong>Customizable Inputs:</strong> Experiment with different deposit amounts to plan effectively.</li>
      <li><strong>Graphical Representation:</strong> Visualize the growth of your savings over the tenure.</li>
    </ul>

    <h4>Maturity and Interest Calculation Formula</h4>
    <p>
      The calculator uses the recurring deposit compound interest formula to estimate the maturity amount:
      <br />
      <code>M = P × (1 + r/n)^(n × t) − 1 × (1 + r/n) / (r/n)</code>
      <br />
      Where:
      <ul>
        <li><strong>M:</strong> Maturity amount</li>
        <li><strong>P:</strong> Monthly deposit</li>
        <li><strong>r:</strong> Annual interest rate (in decimal)</li>
        <li><strong>n:</strong> Compounding frequency (4 for quarterly compounding)</li>
        <li><strong>t:</strong> Time (tenure in years)</li>
      </ul>
    </p>

    <h4>Example Calculation</h4>
    <p>
      <strong>Monthly Deposit:</strong> ₹5,000 <br />
      <strong>Tenure:</strong> 5 years <br />
      <strong>Interest Rate:</strong> 6.8% per annum (compounded quarterly) <br />
      <strong>Formula:</strong> M = 5,000 × [(1 + 0.068/4)^(4 × 5) − 1 × (1 + 0.068/4)] / (0.068/4) <br />
      <strong>Result:</strong> M = ₹3,48,487.72 <br />
      <strong>Interest Earned:</strong> ₹48,487.72
    </p>

    <h4>Benefits of Using the Post Office RD Calculator</h4>
    <ul>
      <li><strong>Financial Planning:</strong> Helps you determine the best monthly deposit amount to meet your goals.</li>
      <li><strong>Transparency:</strong> Provides a clear breakdown of how interest compounds over time.</li>
      <li><strong>Compare Scenarios:</strong> Experiment with different inputs to see how they impact your returns.</li>
      <li><strong>Exportable Results:</strong> Download or share your calculations for future reference.</li>
    </ul>

    <h4>Additional Features of Post Office RD</h4>
    <ul>
      <li><strong>Flexibility:</strong> Defaulted deposits can be paid with a nominal penalty.</li>
      <li><strong>Nomination Facility:</strong> Assign a nominee to ensure smooth transfer of funds in case of unforeseen events.</li>
      <li><strong>Renewal Option:</strong> Continue saving by reinvesting your RD on maturity.</li>
      <li><strong>Accessibility:</strong> Available across all Post Office branches, catering to rural and urban savers alike.</li>
    </ul>

    <h4>FAQs About Post Office RD Calculator</h4>
    <ul>
      <li><strong>Can I withdraw my RD prematurely?</strong> Yes, premature withdrawals are allowed, but penalties may apply.</li>
      <li><strong>What is the minimum deposit amount?</strong> The minimum deposit amount is ₹100 per month.</li>
      <li><strong>What happens if I miss a deposit?</strong> A penalty of ₹1 per ₹100 per month of default is charged.</li>
      <li><strong>Is the RD maturity amount taxable?</strong> Yes, interest earned is taxable as per your income slab.</li>
    </ul>
  </div>
</div>

            </div>
          </div>
          <div className="col-md-3">
            <Sidebar />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PostOfficeRDCalculator;
