import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Layout from '../Component/Layout';
import Sidebar from '../Pages/Sidebar';


const MisCalculator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [principal, setPrincipal] = useState('');
  const [interestRate, setInterestRate] = useState('7.4'); // Default MIS interest rate
  const [investmentTerm, setInvestmentTerm] = useState('5'); // Default term in years
  const [calculationData, setCalculationData] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const p = parseFloat(principal);
    const rate = parseFloat(interestRate) / 100;
    const term = parseInt(investmentTerm);

    // MIS calculation
    const totalInterest = p * rate * term;
    const monthlyIncome = totalInterest / (term * 12);
    const maturityAmount = p + totalInterest;

    setCalculationData({
      monthlyIncome: monthlyIncome.toFixed(2),
      totalInterest: totalInterest.toFixed(2),
      maturityAmount: maturityAmount.toFixed(2),
    });
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([
      {
        'Principal Amount:': principal,
        'Interest Rate:': `${interestRate}%`,
        'Term (Years):': investmentTerm,
        'Monthly Income:': calculationData.monthlyIncome,
        'Total Interest Earned:': calculationData.totalInterest,
        'Maturity Amount:': calculationData.maturityAmount,
      },
    ]);

    XLSX.utils.book_append_sheet(wb, ws, 'MISCalculatorData');
    XLSX.writeFile(wb, 'MIS_Calculator_Data.xlsx');
  };

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter.format(amount);
  };

  return (
    <Layout
    pageTitle="Post Office MIS Calculator | PostOfficeCalculators"
    metaDescription="Calculate monthly income from Post Office MIS scheme with our easy-to-use Post Office MIS Calculator."
    metaKeywords="Post Office MIS Calculator, MIS returns, Post Office monthly income, savings calculator"
    canonicalUrl="https://postofficecalculators.com/post-office-mis-calculator"
    robotsContent="index, follow"
    ogTitle="Post Office MIS Calculator | PostOfficeCalculators"
    ogDescription="Use our Post Office MIS Calculator to determine your monthly income from the Post Office Monthly Income Scheme."
    ogUrl="https://postofficecalculators.com/post-office-mis-calculator"
    ogImage="https://postofficecalculators.com/img/logo.png"
    schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://postofficecalculators.com/post-office-mis-calculator",
        "name": "Post Office MIS Calculator",
        "description": "Calculate your monthly income from the Post Office Monthly Income Scheme with our easy-to-use calculator.",
        "publisher": {
            "@type": "Organization",
            "name": "PostOfficeCalculators",
            "logo": {
                "@type": "ImageObject",
                "url": "https://postofficecalculators.com/img/logo.png"
            }
        }
    }}
>

      <br />

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-8 main-section">
            <div className="container">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/all-tools" style={{ textDecoration: 'none', color: 'black' }}>All Tools</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">MIS Calculator</li>
                </ol>
              </nav>

              <form onSubmit={handleSubmit}>
                <h1 className="text-center">Post Office MIS Calculator</h1>
                <p className="text-center">Estimate your monthly income and total returns from the Post Office MIS.</p>
                <br />
                <div className="container text-center">
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="Principal" className="form-label label">
                        <b>Principal Amount (INR): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={principal}
                        className="form-control"
                        onChange={(e) => setPrincipal(e.target.value)}
                        placeholder="Enter Principal Amount"
                        required
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="InterestRate" className="form-label label">
                        <b>Interest Rate (Annual %): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={interestRate}
                        className="form-control"
                        onChange={(e) => setInterestRate(e.target.value)}
                        placeholder="Enter Interest Rate"
                        required
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="Term" className="form-label label">
                        <b>Investment Term (Years): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={investmentTerm}
                        className="form-control"
                        onChange={(e) => setInvestmentTerm(e.target.value)}
                        placeholder="Enter Investment Term"
                        required
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-md-12">
                      <button type="submit" className="btn btn-success">Calculate</button>
                    </div>
                  </div>
                </div>
              </form>

              {calculationData && (
                <div className="container">
                  <hr />
                  <h5>Summary :</h5>
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th scope="col">Principal Amount:</th>
                          <td>{formatCurrency(principal)}</td>
                        </tr>
                        <tr>
                          <th scope="col">Monthly Income:</th>
                          <td>{formatCurrency(calculationData.monthlyIncome)}</td>
                        </tr>
                        <tr>
                          <th scope="col">Total Interest Earned:</th>
                          <td>{formatCurrency(calculationData.totalInterest)}</td>
                        </tr>
                        <tr>
                          <th scope="col">Maturity Amount:</th>
                          <td>{formatCurrency(calculationData.maturityAmount)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button className="btn btn-success" onClick={exportToExcel}>Export to Excel</button>
                </div>
              )}
              <div className="container">
  <div className="row" style={{ marginTop: '50px' }}>
    <hr />
    <h3 className="text-center">About Post Office MIS Calculator</h3>
    <p>
      The Post Office MIS (Monthly Income Scheme) Calculator is a user-friendly tool designed to help individuals estimate their monthly income from the Post Office MIS scheme. 
      This secure, government-backed savings instrument offers guaranteed monthly returns and is an excellent option for individuals looking for regular income with low risk.
    </p>

    <h4>How the Calculator Works</h4>
    <p>
      The Post Office MIS Calculator simplifies the process of calculating the monthly income from your investments. Based on the inputs provided, it estimates:
      <ul>
        <li><strong>Monthly Income:</strong> The fixed monthly income you will receive based on the invested amount and interest rate.</li>
        <li><strong>Total Investment:</strong> The amount you invest in the MIS scheme.</li>
      </ul>
    </p>

    <h4>Why Choose Post Office MIS?</h4>
    <ul>
      <li><strong>Government-Backed Security:</strong> MIS is a risk-free savings option supported by the Government of India.</li>
      <li><strong>Fixed Monthly Income:</strong> Earn a fixed interest rate paid monthly.</li>
      <li><strong>Tax Benefits:</strong> Enjoy tax benefits under Section 80C of the Income Tax Act.</li>
      <li><strong>Flexible Investment:</strong> Start with as little as ₹1,000, with a maximum investment limit of ₹4.5 lakh for individuals and ₹9 lakh for joint accounts.</li>
      <li><strong>Compounding Benefits:</strong> Interest is paid monthly and not reinvested but provides a steady cash flow.</li>
    </ul>

    <h4>Input Parameters</h4>
    <p>
      To calculate your monthly income, the MIS Calculator requires the following inputs:
      <ul>
        <li><strong>Investment Amount (₹):</strong> The principal amount you wish to invest.</li>
        <li><strong>Interest Rate:</strong> The current annual interest rate (updated quarterly by the government).</li>
        <li><strong>Tenure:</strong> The fixed maturity period of 5 years (for the standard MIS scheme).</li>
      </ul>
    </p>

    <h4>Features of the Post Office MIS Calculator</h4>
    <ul>
      <li><strong>Easy to Use:</strong> Simple interface for quick and accurate calculations.</li>
      <li><strong>Real-Time Results:</strong> Instant estimates based on the latest interest rates.</li>
      <li><strong>Comprehensive Breakdown:</strong> Displays the monthly income and total invested amount.</li>
      <li><strong>Customizable Inputs:</strong> Experiment with different investment amounts to plan your finances effectively.</li>
      <li><strong>Graphical Insights:</strong> Visualize your income flow over the investment period.</li>
    </ul>

    <h4>Monthly Income Calculation Formula</h4>
    <p>
      The calculator uses a simple interest formula to determine the monthly income:
      <br />
      <code>Monthly Income = P × (r / 12)</code>
      <br />
      Where:
      <ul>
        <li><strong>Monthly Income:</strong> The income earned monthly.</li>
        <li><strong>P:</strong> Principal amount (investment).</li>
        <li><strong>r:</strong> Annual interest rate (in decimal).</li>
      </ul>
    </p>

    <h4>Example Calculation</h4>
    <p>
      <strong>Investment Amount:</strong> ₹1,00,000 <br />
      <strong>Interest Rate:</strong> 7.4% per annum (compounded monthly) <br />
      <strong>Formula:</strong> Monthly Income = 1,00,000 × (0.074 / 12) <br />
      <strong>Result:</strong> Monthly Income = ₹616.67 (approx.) <br />
    </p>

    <h4>Benefits of Using the Post Office MIS Calculator</h4>
    <ul>
      <li><strong>Effective Planning:</strong> Helps you forecast monthly income and align your financial goals.</li>
      <li><strong>Transparency:</strong> Provides a clear breakdown of principal, monthly income, and total amount invested.</li>
      <li><strong>Comparison Tool:</strong> Compare different investment amounts to find the most beneficial strategy for your monthly income.</li>
      <li><strong>Save Time:</strong> No need for manual calculations; results are instantly available.</li>
    </ul>

    <h4>Additional Features of Post Office MIS</h4>
    <ul>
      <li><strong>Auto-Renewal:</strong> Option to reinvest the maturity amount for another term after the 5-year period.</li>
      <li><strong>Loan Facility:</strong> MIS accounts can be used as collateral for loans.</li>
      <li><strong>Nomination Option:</strong> Nominate family members for hassle-free claim processing.</li>
      <li><strong>Wide Reach:</strong> Available at all post offices across India.</li>
    </ul>

    <h4>FAQs About Post Office MIS Calculator</h4>
    <ul>
      <li><strong>Is the interest taxable?</strong> Yes, the interest earned is taxable, but you can claim tax deductions under Section 80C.</li>
      <li><strong>Can I withdraw before maturity?</strong> Premature withdrawal is allowed, but it involves penalties.</li>
      <li><strong>Is there a minimum investment amount?</strong> Yes, the minimum investment is ₹1,000.</li>
      <li><strong>Are MIS investments safe?</strong> Yes, MIS is a government-backed scheme offering assured monthly returns.</li>
    </ul>
  </div>
</div>

            </div>
          </div>
          <div className="col-md-3">
            <Sidebar />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MisCalculator;
