import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css'
const Header = () => {
  const [showLinks, setShowLinks] = useState(false);

  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };



  return (
    <>
      <section>
        <nav className="navbar navbar-expand-lg sticky-top navbar-light" style={{ height: '100px', backgroundColor:"#556b2f" }}>
          <div className="container-fluid">
            <NavLink className="navbar-brand" to="/">
              <img className="img-fluid" src="/img/logo.png" width={'200px'} alt="logo-img"  />
            </NavLink>
            <div className="collapse navbar-collapse position-absolute top-50 start-50 translate-middle">
            <ul className={`navbar-nav mx-auto ${showLinks ? 'd-none d-lg-block' : ''}`}>
                <li className="nav-item"><NavLink className="nav-link text-white" to="/" ><b>Home</b></NavLink></li>
                <li className="nav-item"><NavLink className="nav-link text-white" to="/about-us" ><b>About Us</b></NavLink></li>
                <li className="nav-item"><NavLink className="nav-link text-white" to="/all-tools" ><b>All Tools</b></NavLink></li>
                <li className="nav-item"><NavLink className="nav-link text-white" to="/contact-us" ><b>Contact Us</b></NavLink></li>
              </ul>
            </div>
            <div className="d-lg-none">
              <button className="btn navbar-burger p-0" onClick={toggleLinks}>
                <svg className="text-primary" width="51" height="51" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="56" height="56" rx="28" fill="currentColor"></rect>
                  <path d="M37 32H19M37 24H19" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
              </button>
            </div>
          </div>
        </nav>
        <div className={`navbar-menu position-fixed top-0 start-0 bottom-0 w-75 mw-xs ${showLinks ? 'd-block' : 'd-none'}`} style={{ zIndex: '9999' }}>
          <div className="navbar-close navbar-backdrop position-fixed top-0 start-0 end-0 bottom-0 bg-dark" style={{ opacity: '75%' }}></div>
          <nav className="position-relative h-80 w-100 d-flex flex-column justify-content-between py-8 px-8 bg-white overflow-auto">
            <div className="d-flex align-items-center">
              <NavLink className="navbar-close" to="#" onClick={toggleLinks}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 18L18 6M6 6L18 18" stroke="#111827" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
              </NavLink>
            </div>
            <div className="py-16">
              <ul className="nav flex-column">
                <li className="nav-item mb-8"><NavLink className="nav-link text-dark" to="/">Home</NavLink></li>
                <li className="nav-item mb-8"><NavLink className="nav-link text-dark" to="/about-us">About Us</NavLink></li>
                <li className="nav-item"><NavLink className="nav-link text-dark" to="/all-tools" ><b>All Tools</b></NavLink></li>
                <li className="nav-item"><NavLink className="nav-link text-dark" to="/contact-us">Contact Us</NavLink></li>
              </ul>
            </div>
          </nav>
        </div>
      </section>
    </>
  );
};

export default Header;
