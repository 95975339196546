import React, { useEffect } from "react";
import Layout from '../Component/Layout';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout
    pageTitle="Privacy Policy | PostOfficeCalculators"
    metaDescription="Read the Privacy Policy for PostOfficeCalculators to understand how we collect, use, and protect your personal information."
    metaKeywords="privacy policy, data protection, privacy, PostOfficeCalculators"
    canonicalUrl="https://postofficecalculators.com/privacy-policy"
    robotsContent="index, follow"
    ogTitle="Privacy Policy | PostOfficeCalculators"
    ogDescription="Understand how PostOfficeCalculators collects and protects your personal information."
    ogUrl="https://postofficecalculators.com/privacy-policy"
    ogImage="https://postofficecalculators.com/img/logo.png"
    schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://postofficecalculators.com/privacy-policy",
        "name": "Privacy Policy",
        "description": "Understand how we collect, use, and protect your personal information at PostOfficeCalculators.",
        "publisher": {
            "@type": "Organization",
            "name": "PostOfficeCalculators",
            "logo": {
                "@type": "ImageObject",
                "url": "https://postofficecalculators.com/img/logo.png"
            }
        }
    }}
>

            <div className="container-fluid py-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="d-flex justify-content-center">
                    <div
                        className="card shadow-lg mt-4"
                        style={{
                            backgroundColor: '#e0e0e0',
                            borderRadius: '15px',
                            maxWidth: '85%',
                            color: '#333333',
                        }}
                    >
                        <div className="card-body p-5">
                            <header className="text-center mb-4">
                                <h1 className="display-4" style={{ color: '#343a40', fontWeight: 'bold' }}>Privacy Policy</h1>
                                <p className="lead" style={{ color: '#495057' }}>
                                    Learn about our policies and procedures regarding your privacy.
                                </p>
                            </header>

                            <section>
                                <p>
                                    This Privacy Policy describes Our policies and procedures on the collection, use, 
                                    and disclosure of Your information when You use the Service and tells You about 
                                    Your privacy rights and how the law protects You.
                                </p>
                                <p>
                                    We use Your Personal data to provide and improve the Service. By using the Service, 
                                    You agree to the collection and use of information in accordance with this Privacy Policy.
                                </p>
                            </section>

                            <section>
                                <h2 className="h4 text-dark" style={{ fontWeight: 'bold' }}>Interpretation and Definitions</h2>
                                <h3 className="h5 text-dark" style={{ fontWeight: 'bold' }}>Interpretation</h3>
                                <p>
                                    The words of which the initial letter is capitalized have meanings defined under the following conditions. 
                                    The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
                                </p>

                                <h3 className="h5 text-dark" style={{ fontWeight: 'bold' }}>Definitions</h3>
                                <p>
                                    For the purposes of this Privacy Policy:
                                </p>
                                <ul className="list-group">
                                    <li className="list-group-item">
                                        <strong>Account:</strong> A unique account created for You to access our Service or parts of our Service.
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Affiliate:</strong> An entity that controls, is controlled by, or is under common control with a party, 
                                        where "control" means ownership of 50% or more of the shares, equity interest, or other securities 
                                        entitled to vote for election of directors or other managing authority.
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Company:</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Post Office Calculator.
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Cookies:</strong> Small files placed on Your computer, mobile device, or any other device by a website, containing details of Your browsing history on that website among its many uses.
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Country:</strong> Refers to: Madhya Pradesh, India.
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Device:</strong> Any device that can access the Service such as a computer, cellphone, or digital tablet.
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Personal Data:</strong> Any information that relates to an identified or identifiable individual.
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Service:</strong> Refers to the Website.
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Service Provider:</strong> Any natural or legal person who processes the data on behalf of the Company. 
                                        It refers to third-party companies or individuals employed by the Company to facilitate the Service, 
                                        provide the Service on behalf of the Company, perform services related to the Service, or assist the Company in analyzing how the Service is used.
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Usage Data:</strong> Data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Website:</strong> Refers to Post Office Calculator, accessible from <Link to="/" target="_blank" rel="noopener noreferrer">https://postofficecalculators.com</Link>.
                                    </li>
                                    <li className="list-group-item">
                                        <strong>You:</strong> The individual accessing or using the Service, or the company or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                                    </li>
                                </ul>
                            </section>
                    <br/>       
                    <section id="collecting-and-using-data" className="mb-5">
    <h2 className="h4 text-dark" style={{ fontWeight: 'bold' }}>Collecting and Using Your Personal Data</h2>

    <section id="personal-data" className="mb-4">
        <h3 className="h5 text-dark" style={{ fontWeight: 'bold' }}>Personal Data</h3>
        <p>
            While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
        </p>
        <ul className="list-group">
            <li className="list-group-item">Email address</li>
            <li className="list-group-item">Phone number</li>
            <li className="list-group-item">Usage Data</li>
        </ul>
    </section>

    <section id="usage-data" className="mb-4">
        <h3 className="h5 text-dark" style={{ fontWeight: 'bold' }}>Usage Data</h3>
        <p>
            Usage Data is collected automatically when using the Service. Usage Data may include information such as:
        </p>
        <ul className="list-group">
            <li className="list-group-item">Your Device's Internet Protocol address (e.g. IP address)</li>
            <li className="list-group-item">Browser type and version</li>
            <li className="list-group-item">Pages of our Service that You visit</li>
            <li className="list-group-item">Time and date of Your visit</li>
            <li className="list-group-item">Time spent on those pages</li>
            <li className="list-group-item">Unique device identifiers</li>
            <li className="list-group-item">Other diagnostic data</li>
        </ul>
        <p>
            When You access the Service through a mobile device, We may also collect certain additional information, such as:
        </p>
        <ul className="list-group">
            <li className="list-group-item">The type of mobile device You use</li>
            <li className="list-group-item">Your mobile device unique ID</li>
            <li className="list-group-item">The IP address of Your mobile device</li>
            <li className="list-group-item">Your mobile operating system</li>
            <li className="list-group-item">The type of mobile Internet browser You use</li>
            <li className="list-group-item">Other diagnostic data</li>
        </ul>
    </section>

    <section id="tracking-and-cookies" className="mb-4">
        <h3 className="h5 text-dark" style={{ fontWeight: 'bold' }}>Tracking Technologies and Cookies</h3>
        <p>
            We use Cookies and similar tracking technologies to track activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:
        </p>
        <ul className="list-group">
            <li className="list-group-item">
                <strong>Cookies or Browser Cookies:</strong> Small files placed on Your Device. You can instruct Your browser to refuse all Cookies or indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service.
            </li>
            <li className="list-group-item">
                <strong>Web Beacons:</strong> Small electronic files (e.g., clear gifs, pixel tags, and single-pixel gifs) used to count users who visited pages or opened emails, among other purposes.
            </li>
        </ul>
        <p>
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.
        </p>
        <p>
            We use Cookies for:
        </p>
        <ul className="list-group">
            <li className="list-group-item">
                <strong>Necessary / Essential Cookies:</strong> Essential for providing services available through the Website.
            </li>
            <li className="list-group-item">
                <strong>Cookies Policy / Notice Acceptance Cookies:</strong> Identify if users have accepted the use of cookies.
            </li>
            <li className="list-group-item">
                <strong>Functionality Cookies:</strong> Remember choices You make (e.g., login details or language preferences).
            </li>
        </ul>
    </section>
</section>
<section id="use-of-personal-data">
    <h2>Use of Your Personal Data</h2>
    <p>The Company may use Personal Data for the following purposes:</p>
    <ul>
        <li><strong>To provide and maintain our Service:</strong> Including to monitor the usage of our Service.</li>
        <li><strong>To manage Your Account:</strong> To manage Your registration as a user of the Service.</li>
        <li><strong>For the performance of a contract:</strong> Development, compliance, and undertaking of contracts.</li>
        <li><strong>To contact You:</strong> Via email, telephone, SMS, or other equivalent electronic communication.</li>
        <li><strong>To provide updates and offers:</strong> General information about goods, services, and events similar to those you have already purchased or enquired about.</li>
        <li><strong>To manage Your requests:</strong> Attend and manage requests to Us.</li>
        <li><strong>For business transfers:</strong> Evaluation or conduct of mergers, restructuring, or similar proceedings.</li>
        <li><strong>For other purposes:</strong> Data analysis, identifying usage trends, and improving services.</li>
    </ul>
</section>

<section id="sharing-personal-data">
    <h2>Sharing Your Personal Data</h2>
    <p>We may share Your personal information in the following situations:</p>
    <ul>
        <li><strong>With Service Providers:</strong> For monitoring and analyzing service usage or contacting You.</li>
        <li><strong>For business transfers:</strong> During mergers, sales, or financing negotiations.</li>
        <li><strong>With Affiliates:</strong> Subject to honoring this Privacy Policy.</li>
        <li><strong>With business partners:</strong> To offer products, services, or promotions.</li>
        <li><strong>With other users:</strong> Information shared in public areas may be publicly distributed.</li>
        <li><strong>With Your consent:</strong> For any other purpose with Your explicit consent.</li>
    </ul>
</section>

<section id="retention-of-data">
    <h2>Retention of Your Personal Data</h2>
    <p>
        The Company will retain Your Personal Data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy. 
        Usage Data is retained for shorter periods unless required for security, functionality improvements, or legal obligations.
    </p>
</section>

<section id="data-transfer">
    <h2>Transfer of Your Personal Data</h2>
    <p>
        Your information, including Personal Data, may be transferred and maintained on computers outside your jurisdiction. The Company ensures adequate controls are in place for the secure processing of Your data.
    </p>
</section>

<section id="delete-personal-data">
    <h2>Delete Your Personal Data</h2>
    <p>
        You have the right to request the deletion of Personal Data collected about You. You can manage or delete your information from your account settings or by contacting Us. 
        Note that some data may be retained to comply with legal obligations.
    </p>
</section>
<section id="disclosure-personal-data">
    <h2>Disclosure of Your Personal Data</h2>

    <h3>Business Transactions</h3>
    <p>
        If the Company is involved in a merger, acquisition, or asset sale, Your Personal Data may be transferred. 
        We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
    </p>

    <h3>Law Enforcement</h3>
    <p>
        Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law 
        or in response to valid requests by public authorities (e.g., a court or a government agency).
    </p>

    <h3>Google Ads and Third-Party Services</h3>
    <p>
        Our website uses Google Ads to display ads. Google, as a third-party vendor, uses cookies to serve ads on our site. 
        These cookies enable Google to serve ads to users based on their visits to our website and other sites on the internet.
    </p>
    <p>
        Users may opt out of personalized advertising by visiting the 
        <Link to="https://www.google.com/settings/ads" target="_blank"> https://www.google.com/settings/ads </Link>.
    </p>
    <p>
        Our website may also use third-party services like Google Analytics to analyze website traffic and user behavior. 
        We are not responsible for the privacy practices or content of third-party websites.
    </p>
</section>

<section id="legal-requirements">
    <h2>Other Legal Requirements</h2>
    <p>
        The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
    </p>
    <ul>
        <li>Comply with a legal obligation</li>
        <li>Protect and defend the rights or property of the Company</li>
        <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
        <li>Protect the personal safety of Users of the Service or the public</li>
        <li>Protect against legal liability</li>
    </ul>
</section>

<section id="data-security">
    <h2>Security of Your Personal Data</h2>
    <p>
        The security of Your Personal Data is important to Us, but no method of transmission over the Internet or electronic storage is 100% secure. 
        While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
    </p>
</section>

<section id="childrens-privacy">
    <h2>Children's Privacy</h2>
    <p>
        Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. 
        If You are a parent or guardian and are aware that Your child has provided Us with Personal Data, please contact Us. 
        If We become aware of such data collection without parental consent, We will remove the data from Our servers.
    </p>
    <p>
        If Your country requires parental consent for data processing, we may require Your parent's consent before collecting and using such information.
    </p>
</section>

<section id="links-other-websites">
    <h2>Links to Other Websites</h2>
    <p>
        Our Service may contain links to other websites not operated by Us. 
        We strongly advise You to review the Privacy Policy of every site You visit. 
        We are not responsible for the content, privacy policies, or practices of third-party sites or services.
    </p>
</section>

<section id="privacy-policy-changes">
    <h2>Changes to this Privacy Policy</h2>
    <p>
        We may update Our Privacy Policy from time to time. Changes are effective when posted on this page, and we will notify You via email or prominent notices on our Service. 
        You are advised to review this Privacy Policy periodically for updates.
    </p>
</section>

<section id="contact-us">
    <h2>Contact Us</h2>
    <p>If you have any questions about this Privacy Policy, You can contact us:</p>
    <ul>
        <li>By email: <Link to="support@postofficecalculaters.com">support@postofficecalculaters.com</Link></li>
        <li>By visiting this page on our website: <Link to="/contact-us">Contact</Link></li>
    </ul>
</section>


                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default PrivacyPolicy;
