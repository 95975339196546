import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Layout from '../Component/Layout';
import Sidebar from '../Pages/Sidebar';

const PostOfficeCalculator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [principal, setPrincipal] = useState('');
  const [interestRate, setInterestRate] = useState('7'); // Default interest rate
  const [investmentTerm, setInvestmentTerm] = useState('');
  const [calculationData, setCalculationData] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const P = parseFloat(principal);
    const rate = parseFloat(interestRate);
    const term = parseInt(investmentTerm);

    // Simple interest calculation
    const interest = (P * rate * term) / 100;
    const totalAmount = P + interest;

    setCalculationData({
      totalAmount: totalAmount.toFixed(2),
      interest: interest.toFixed(2),
      years: Array.from({ length: term }, (_, i) => i + 1),
    });
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([{
      'Principal Amount:': principal,
      'Total Interest:': calculationData.interest,
      'Total Amount:': calculationData.totalAmount,
    }]);

    XLSX.utils.book_append_sheet(wb, ws, 'PostOfficeData');
    XLSX.writeFile(wb, 'Post_Office_Calculator_Data.xlsx');
  };

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter.format(amount);
  };

  return (
    <Layout
      pageTitle="Post Office FD Calculator | PostOfficeCalculators"
      metaDescription="Calculate returns on Post Office savings scheme with our Post Office Calculator."
      metaKeywords="Post Office Calculator, Post Office returns, savings scheme calculator"
      canonicalUrl="https://postofficecalculators.com/post-office-fd-calculator"
      robotsContent="index, follow"
      ogTitle="Post Office Calculator | PostOfficeCalculators"
      ogDescription="Calculate returns on your Post Office investment schemes with our calculator."
      ogUrl="https://postofficecalculators.com/post-office-fd-calculator"
      ogImage="https://postofficecalculators.com/img/logo.png"
      schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://postofficecalculators.com/post-office-fd-calculator",
        "name": "Post Office Calculator",
        "description": "Calculate returns on Post Office savings schemes using our calculator.",
        "publisher": {
          "@type": "Organization",
          "name": "PostOfficeCalculators",
          "logo": {
            "@type": "ImageObject",
            "url": "https://postofficecalculators.com/img/logo.png"
          }
        }
      }}
    >
      <br />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-8 main-section">
            <div className="container">
              <div className="header-ads"></div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/all-tools" style={{ textDecoration: 'none', color: 'black' }}>All Tools</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Post Office FD Calculator</li>
                </ol>
              </nav>

              <form onSubmit={handleSubmit}>
                <h1 className="text-center">Post Office FD Calculator</h1>
                <p className="text-center">Estimate the returns on your Post Office savings investment.</p>
                <br />
                <div className="container text-center">
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="Principal" className="form-label label">
                        <b>Principal Amount (INR): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={principal}
                        className="form-control"
                        onChange={(e) => setPrincipal(e.target.value)}
                        placeholder="Enter Principal Amount"
                        required
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="InterestRate" className="form-label label">
                        <b>Interest Rate (Annual %): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={interestRate}
                        className="form-control"
                        onChange={(e) => setInterestRate(e.target.value)}
                        placeholder="Enter Interest Rate"
                        required
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="Term" className="form-label label">
                        <b>Investment Term (Years): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={investmentTerm}
                        className="form-control"
                        onChange={(e) => setInvestmentTerm(e.target.value)}
                        placeholder="Enter Investment Term"
                        required
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-md-12">
                      <button type="submit" className="btn btn-success">Calculate</button>
                    </div>
                  </div>
                </div>
              </form>

              {calculationData && (
                <div className="container">
                  <hr />
                  <h5>Summary :</h5>
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th scope="col">Total Amount:</th>
                          <td>{formatCurrency(calculationData.totalAmount)}</td>
                        </tr>
                        <tr>
                          <th scope="col">Total Interest:</th>
                          <td>{formatCurrency(calculationData.interest)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button className="btn btn-success" onClick={exportToExcel}>Export to Excel</button>
                </div>
              )}

              <div className="container">
  <div className="row" style={{ marginTop: '50px' }}>
    <hr />
    <h3 className="text-center">About Post Office FD Calculator</h3>
    <br />
    <p>
      The Post Office Fixed Deposit (FD) Calculator is a reliable tool designed to help users estimate the returns on their fixed deposit investments with the Indian Post Office. 
      This scheme is a government-backed savings option that guarantees secure returns, making it ideal for individuals looking for risk-free investment opportunities. 
      The calculator provides an accurate estimate of the maturity amount and the interest earned over the selected tenure, assisting users in effective financial planning.
    </p>

    <h4>How the Calculator Works</h4>
    <p>
      The Post Office FD Calculator simplifies the complex calculations of interest compounding, allowing you to focus on your investment goals. Based on the input values provided, the calculator estimates:
      <ul>
        <li><strong>Maturity Amount:</strong> The total amount you will receive at the end of the FD tenure, including the principal and compounded interest.</li>
        <li><strong>Interest Earned:</strong> The total interest generated over the deposit period, helping you understand the profitability of your investment.</li>
        <li><strong>Total Investment:</strong> The principal amount you invested initially.</li>
      </ul>
    </p>

    <h4>Why Choose Post Office FD?</h4>
    <ul>
      <li><strong>Government-Backed Security:</strong> Post Office FDs are among the safest investment options, backed by the Government of India.</li>
      <li><strong>Flexible Tenures:</strong> Choose from 1, 2, 3, or 5 years based on your financial goals.</li>
      <li><strong>Quarterly Compounding:</strong> Interest is compounded quarterly, offering higher returns compared to simple interest investments.</li>
      <li><strong>Tax Benefits:</strong> The 5-year FD qualifies for tax deductions under Section 80C of the Income Tax Act.</li>
      <li><strong>Guaranteed Returns:</strong> Fixed interest rates ensure predictable and assured returns, making it ideal for conservative investors.</li>
    </ul>

    <h4>Input Parameters</h4>
    <p>
      To provide accurate results, the Post Office FD Calculator requires the following inputs:
      <ul>
        <li><strong>Deposit Amount (₹):</strong> The principal amount you wish to invest.</li>
        <li><strong>Tenure:</strong> Choose between 1, 2, 3, or 5 years based on your financial planning.</li>
        <li><strong>Interest Rate:</strong> The current annual interest rates offered for the selected tenure (updated as per government notifications).</li>
      </ul>
    </p>

    <h4>Features of the Post Office FD Calculator</h4>
    <ul>
      <li><strong>Simple and User-Friendly Interface:</strong> Easily calculate returns by entering a few details.</li>
      <li><strong>Real-Time Results:</strong> Instant calculations based on the latest interest rates.</li>
      <li><strong>Detailed Breakdown:</strong> View the maturity amount and interest earned over time.</li>
      <li><strong>Customizable Inputs:</strong> Experiment with different deposit amounts and tenures to plan effectively.</li>
      <li><strong>Graphical Representation:</strong> Visualize the growth of your investment over the chosen tenure.</li>
    </ul>

    <h4>Maturity and Interest Calculation Formula</h4>
    <p>
      The calculator uses the compound interest formula to estimate the maturity amount:
      <br />
      <code>M = P × (1 + r/n)^(n × t)</code>
      <br />
      Where:
      <ul>
        <li><strong>M:</strong> Maturity amount</li>
        <li><strong>P:</strong> Principal amount</li>
        <li><strong>r:</strong> Annual interest rate (in decimal)</li>
        <li><strong>n:</strong> Compounding frequency (4 for quarterly compounding)</li>
        <li><strong>t:</strong> Time (tenure in years)</li>
      </ul>
    </p>

    <h4>Example Calculation</h4>
    <p>
      <strong>Deposit Amount:</strong> ₹1,00,000 <br />
      <strong>Tenure:</strong> 5 years <br />
      <strong>Interest Rate:</strong> 7.5% per annum (compounded quarterly) <br />
      <strong>Formula:</strong> M = 1,00,000 × (1 + 0.075/4)^(4 × 5) <br />
      <strong>Result:</strong> M = ₹1,44,903.73 <br />
      <strong>Interest Earned:</strong> ₹44,903.73
    </p>

    <h4>Benefits of Using the Post Office FD Calculator</h4>
    <ul>
      <li><strong>Financial Planning:</strong> Helps you determine the best tenure and deposit amount to meet your financial goals.</li>
      <li><strong>Transparency:</strong> Provides a clear breakdown of how interest compounds over time.</li>
      <li><strong>Compare Scenarios:</strong> Experiment with different inputs to see how they impact your returns.</li>
      <li><strong>Exportable Results:</strong> Download or share your calculations for future reference.</li>
    </ul>

    <h4>Additional Features of Post Office FD</h4>
    <ul>
      <li><strong>Liquidity:</strong> Premature withdrawals are allowed, subject to certain conditions.</li>
      <li><strong>Nomination Facility:</strong> Assign a nominee to ensure smooth transfer of funds in case of unforeseen events.</li>
      <li><strong>Renewal Option:</strong> Easily reinvest your FD on maturity for continued returns.</li>
      <li><strong>High Accessibility:</strong> Available across all Post Office branches, ensuring convenience for rural and urban investors alike.</li>
    </ul>

    <h4>FAQs About Post Office FD Calculator</h4>
    <ul>
      <li><strong>Can I withdraw my FD prematurely?</strong> Yes, premature withdrawals are allowed, but a penalty may apply.</li>
      <li><strong>Is there a minimum deposit amount?</strong> Yes, the minimum deposit amount is ₹1,000.</li>
      <li><strong>What happens if I miss renewing my FD on maturity?</strong> The maturity amount will earn simple interest at the savings account rate until withdrawn or renewed.</li>
      <li><strong>Are Post Office FD returns taxable?</strong> Yes, interest earned is taxable. However, the 5-year FD qualifies for tax deduction under Section 80C.</li>
    </ul>
  </div>
</div>

            </div>
          </div>
          <div className="col-md-3">
            <Sidebar />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PostOfficeCalculator;
