import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Layout from '../Component/Layout';
import Sidebar from '../Pages/Sidebar';

const PostOfficeTDCalculator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [principal, setPrincipal] = useState('');
  const [interestRate, setInterestRate] = useState('7'); // Default interest rate
  const [investmentTerm, setInvestmentTerm] = useState('');
  const [isCompound, setIsCompound] = useState(true); // Toggle between Compound and Simple Interest
  const [calculationData, setCalculationData] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const P = parseFloat(principal);
    const rate = parseFloat(interestRate);
    const term = parseInt(investmentTerm);

    let totalAmount = 0;
    let interest = 0;

    if (isCompound) {
      // Compound interest calculation
      totalAmount = P * Math.pow((1 + rate / 100), term);
      interest = totalAmount - P;
    } else {
      // Simple interest calculation
      interest = (P * rate * term) / 100;
      totalAmount = P + interest;
    }

    setCalculationData({
      totalAmount: totalAmount.toFixed(2),
      interest: interest.toFixed(2),
    });
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([{
      'Principal Amount:': principal,
      'Interest Rate:': interestRate,
      'Total Interest:': calculationData.interest,
      'Total Amount:': calculationData.totalAmount,
    }]);

    XLSX.utils.book_append_sheet(wb, ws, 'PostOfficeTDData');
    XLSX.writeFile(wb, 'Post_Office_TD_Calculator_Data.xlsx');
  };

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter.format(amount);
  };

  return (
    <Layout
      pageTitle="Post Office TD Calculator | PostOfficeCalculators"
      metaDescription="Calculate returns on Post Office Time Deposit schemes with our TD Calculator."
      metaKeywords="Post Office TD Calculator, Time Deposit returns, Post Office investment calculator"
      canonicalUrl="https://postofficecalculators.com/post-office-td-calculator"
      robotsContent="index, follow"
      ogTitle="Post Office TD Calculator | PostOfficeCalculators"
      ogDescription="Estimate returns on your Post Office Time Deposit investments."
      ogUrl="https://postofficecalculators.com/post-office-td-calculator"
      ogImage="https://postofficecalculators.com/img/logo.png"
      schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://postofficecalculators.com/post-office-td-calculator",
        "name": "Post Office TD Calculator",
        "description": "Calculate returns on Post Office Time Deposit schemes using our calculator.",
        "publisher": {
          "@type": "Organization",
          "name": "PostOfficeCalculators",
          "logo": {
            "@type": "ImageObject",
            "url": "https://postofficecalculators.com/img/logo.png"
          }
        }
      }}
    >
    <br />
    
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-8 main-section">
            <div className="container">
              <div className="header-ads"></div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/all-tools" style={{ textDecoration: 'none', color: 'black' }}>All Tools</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Post Office TD Calculator</li>
                </ol>
              </nav>

              <form onSubmit={handleSubmit}>
                <h1 className="text-center">Post Office Time Deposit Calculator</h1>
                <p className="text-center">Estimate the returns on your Post Office Time Deposit investment.</p>
                <br />
                <div className="container text-center">
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="Principal" className="form-label label">
                        <b>Principal Amount (INR): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={principal}
                        className="form-control"
                        onChange={(e) => setPrincipal(e.target.value)}
                        placeholder="Enter Principal Amount"
                        required
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="InterestRate" className="form-label label">
                        <b>Interest Rate (Annual %): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={interestRate}
                        className="form-control"
                        onChange={(e) => setInterestRate(e.target.value)}
                        placeholder="Enter Interest Rate"
                        required
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="Term" className="form-label label">
                        <b>Investment Term (Years): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={investmentTerm}
                        className="form-control"
                        onChange={(e) => setInvestmentTerm(e.target.value)}
                        placeholder="Enter Investment Term"
                        required
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-md-12">
                      <div>
                        <label className="form-label">
                          <input
                            type="checkbox"
                            checked={isCompound}
                            onChange={() => setIsCompound(!isCompound)}
                          /> Use Compound Interest
                        </label>
                      </div>
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-md-12">
                      <button type="submit" className="btn btn-success">Calculate</button>
                    </div>
                  </div>
                </div>
              </form>

              {calculationData && (
                <div className="container">
                  <hr />
                  <h5>Summary :</h5>
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th scope="col">Total Amount:</th>
                          <td>{formatCurrency(calculationData.totalAmount)}</td>
                        </tr>
                        <tr>
                          <th scope="col">Total Interest:</th>
                          <td>{formatCurrency(calculationData.interest)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button className="btn btn-success" onClick={exportToExcel}>Export to Excel</button>
                </div>
              )}
              <div className="row" style={{ marginTop: '50px' }}>
    <hr />
    <h3 className="text-center">About Post Office TD Calculator</h3>
    <p>
      The Post Office Term Deposit (TD) Calculator is a reliable tool designed to help users estimate the returns on their term deposit investments with the Indian Post Office. 
      This scheme is a government-backed fixed deposit option that provides individuals with a higher interest rate than regular savings accounts, making it ideal for long-term investors. 
      The calculator provides an accurate estimate of the maturity amount and the interest earned over the chosen tenure, assisting users in achieving their financial goals.
    </p>

    <h4>How the Calculator Works</h4>
    <p>
      The Post Office TD Calculator simplifies the complex calculations of interest compounding, allowing you to focus on your investment strategy. Based on the input values provided, the calculator estimates:
      <ul>
        <li><strong>Maturity Amount:</strong> The total amount you will receive at the end of the TD tenure, including both the principal and interest earned.</li>
        <li><strong>Interest Earned:</strong> The total interest generated over the deposit period, helping you understand the growth of your investment.</li>
        <li><strong>Total Deposit:</strong> The principal deposit you have made during the tenure.</li>
      </ul>
    </p>

    <h4>Why Choose Post Office TD?</h4>
    <ul>
      <li><strong>Government-Backed Security:</strong> Post Office TDs are among the safest investment options, backed by the Government of India.</li>
      <li><strong>Higher Interest Rates:</strong> Offers better returns than regular savings accounts.</li>
      <li><strong>Flexible Tenure:</strong> Choose a tenure that suits your financial needs (1 to 5 years).</li>
      <li><strong>Quarterly/Monthly Interest:</strong> Receive interest quarterly or monthly, as per your preference.</li>
      <li><strong>Nomination Facility:</strong> Assign a nominee to ensure smooth transfer of funds in case of unforeseen events.</li>
    </ul>

    <h4>Input Parameters</h4>
    <p>
      To provide accurate results, the Post Office TD Calculator requires the following inputs:
      <ul>
        <li><strong>Deposit Amount (₹):</strong> The amount you wish to invest in the TD.</li>
        <li><strong>Tenure:</strong> Choose a tenure (1 to 5 years) for the fixed deposit.</li>
        <li><strong>Interest Rate:</strong> The current annual interest rate offered (updated as per government notifications).</li>
        <li><strong>Interest Payment Frequency:</strong> Choose between quarterly or monthly payments based on your preference.</li>
      </ul>
    </p>

    <h4>Features of the Post Office TD Calculator</h4>
    <ul>
      <li><strong>Simple and User-Friendly Interface:</strong> Easily calculate returns by entering a few details.</li>
      <li><strong>Real-Time Results:</strong> Instant calculations based on the latest interest rates.</li>
      <li><strong>Detailed Breakdown:</strong> View the maturity amount and interest earned over time.</li>
      <li><strong>Customizable Inputs:</strong> Experiment with different deposit amounts and tenures to plan effectively.</li>
      <li><strong>Graphical Representation:</strong> Visualize the growth of your investment over the tenure.</li>
    </ul>

    <h4>Maturity and Interest Calculation Formula</h4>
    <p>
      The calculator uses the term deposit formula to estimate the maturity amount. The interest is compounded quarterly or monthly, depending on your selection:
      <br />
      <code>M = P × (1 + r/n)^(n × t)</code>
      <br />
      Where:
      <ul>
        <li><strong>M:</strong> Maturity amount</li>
        <li><strong>P:</strong> Principal deposit</li>
        <li><strong>r:</strong> Annual interest rate (in decimal)</li>
        <li><strong>n:</strong> Compounding frequency (4 for quarterly, 12 for monthly)</li>
        <li><strong>t:</strong> Time (tenure in years)</li>
      </ul>
    </p>

    <h4>Example Calculation</h4>
    <p>
      <strong>Deposit Amount:</strong> ₹1,00,000 <br />
      <strong>Tenure:</strong> 3 years <br />
      <strong>Interest Rate:</strong> 7% per annum (compounded quarterly) <br />
      <strong>Formula:</strong> M = 1,00,000 × (1 + 0.07/4)^(4 × 3) <br />
      <strong>Result:</strong> M = ₹1,23,185.18 <br />
      <strong>Interest Earned:</strong> ₹23,185.18
    </p>

    <h4>Benefits of Using the Post Office TD Calculator</h4>
    <ul>
      <li><strong>Financial Planning:</strong> Helps you determine the best deposit amount and tenure to meet your goals.</li>
      <li><strong>Transparency:</strong> Provides a clear breakdown of how interest compounds over time.</li>
      <li><strong>Compare Scenarios:</strong> Experiment with different inputs to see how they impact your returns.</li>
      <li><strong>Exportable Results:</strong> Download or share your calculations for future reference.</li>
    </ul>

    <h4>Additional Features of Post Office TD</h4>
    <ul>
      <li><strong>Premature Withdrawal:</strong> Premature withdrawal is allowed with a penalty, and the interest rate is revised accordingly.</li>
      <li><strong>Nomination Facility:</strong> Assign a nominee to ensure smooth transfer of funds in case of unforeseen events.</li>
      <li><strong>Renewal Option:</strong> Continue saving by renewing your TD on maturity.</li>
      <li><strong>Accessibility:</strong> Available across all Post Office branches, catering to rural and urban savers alike.</li>
    </ul>

    <h4>FAQs About Post Office TD Calculator</h4>
    <ul>
      <li><strong>Can I withdraw my TD prematurely?</strong> Yes, premature withdrawals are allowed, but penalties may apply, and the interest rate is revised.</li>
      <li><strong>What is the minimum deposit amount?</strong> The minimum deposit amount is ₹1,000 for a term of 1 year or more.</li>
      <li><strong>What happens if I miss the interest payment date?</strong> The interest will be paid on the due date, and any missed payments will be carried over to the next cycle.</li>
      <li><strong>Is the TD maturity amount taxable?</strong> Yes, interest earned is taxable as per your income tax slab.</li>
    </ul>
  </div>
</div>

            </div>
            <div className="col-md-3">
            <Sidebar />
          </div>
          </div>
          
        </div>
    
    </Layout>
  );
};

export default PostOfficeTDCalculator;
