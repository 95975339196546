import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Layout from '../Component/Layout';
import Sidebar from '../Pages/Sidebar';

const SukanyaSamriddhiCalculator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [yearlyDeposit, setYearlyDeposit] = useState('');
  const [interestRate, setInterestRate] = useState('7.6'); // Default SSY interest rate
  const [investmentTerm, setInvestmentTerm] = useState('');
  const [calculationData, setCalculationData] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const yearly = parseFloat(yearlyDeposit);
    const rate = parseFloat(interestRate) / 100;
    const term = parseInt(investmentTerm); // In years

    // SSY calculation (compound interest annually)
    let maturityAmount = 0;
    let totalDeposit = 0;

    for (let year = 1; year <= term; year++) {
      maturityAmount = (maturityAmount + yearly) * (1 + rate);
      totalDeposit += yearly;
    }

    setCalculationData({
      maturityAmount: maturityAmount.toFixed(2),
      totalDeposit: totalDeposit.toFixed(2),
      interestEarned: (maturityAmount - totalDeposit).toFixed(2),
    });
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([
      {
        'Yearly Deposit:': yearlyDeposit,
        'Total Deposit:': calculationData.totalDeposit,
        'Interest Earned:': calculationData.interestEarned,
        'Maturity Amount:': calculationData.maturityAmount,
      },
    ]);

    XLSX.utils.book_append_sheet(wb, ws, 'SSYCalculatorData');
    XLSX.writeFile(wb, 'Sukanya_Samriddhi_Yojana_Calculator_Data.xlsx');
  };

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter.format(amount);
  };

  return (
    <Layout
    pageTitle="Sukanya Samriddhi Yojana Calculator | PostOfficeCalculators"
    metaDescription="Calculate returns on your Sukanya Samriddhi Yojana (SSY) investment with our easy-to-use calculator."
    metaKeywords="Sukanya Samriddhi Yojana Calculator, SSY returns, Sukanya Samriddhi, investment calculator"
    canonicalUrl="https://postofficecalculators.com/post-office-sukanya-samriddhi-calculator"
    robotsContent="index, follow"
    ogTitle="Sukanya Samriddhi Yojana Calculator | PostOfficeCalculators"
    ogDescription="Use our Sukanya Samriddhi Yojana Calculator to calculate the returns from your SSY investment."
    ogUrl="https://postofficecalculators.com/post-office-sukanya-samriddhi-calculator"
    ogImage="https://postofficecalculators.com/img/logo.png"
    schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://postofficecalculators.com/post-office-sukanya-samriddhi-calculator",
        "name": "Sukanya Samriddhi Yojana Calculator",
        "description": "Calculate the returns on your Sukanya Samriddhi Yojana investment using our easy-to-use SSY Calculator.",
        "publisher": {
            "@type": "Organization",
            "name": "PostOfficeCalculators",
            "logo": {
                "@type": "ImageObject",
                "url": "https://postofficecalculators.com/img/logo.png"
            }
        }
    }}
>

      <br />
      

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-8 main-section">
            <div className="container">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/all-tools" style={{ textDecoration: 'none', color: 'black' }}>All Tools</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Sukanya Samriddhi Yojana Calculator</li>
                </ol>
              </nav>

              <form onSubmit={handleSubmit}>
                <h1 className="text-center">Sukanya Samriddhi Yojana Calculator</h1>
                <p className="text-center">Estimate the maturity value of your Sukanya Samriddhi Yojana account.</p>
                <br />
                <div className="container text-center">
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="YearlyDeposit" className="form-label label">
                        <b>Yearly Deposit (INR): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={yearlyDeposit}
                        className="form-control"
                        onChange={(e) => setYearlyDeposit(e.target.value)}
                        placeholder="Enter Yearly Deposit"
                        required
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="InterestRate" className="form-label label">
                        <b>Interest Rate (Annual %): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={interestRate}
                        className="form-control"
                        onChange={(e) => setInterestRate(e.target.value)}
                        placeholder="Enter Interest Rate"
                        required
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="Term" className="form-label label">
                        <b>Investment Term (Years): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={investmentTerm}
                        className="form-control"
                        onChange={(e) => setInvestmentTerm(e.target.value)}
                        placeholder="Enter Investment Term"
                        required
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-md-12">
                      <button type="submit" className="btn btn-success">Calculate</button>
                    </div>
                  </div>
                </div>
              </form>

              {calculationData && (
                <div className="container">
                  <hr />
                  <h5>Summary :</h5>
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th scope="col">Total Deposit:</th>
                          <td>{formatCurrency(calculationData.totalDeposit)}</td>
                        </tr>
                        <tr>
                          <th scope="col">Interest Earned:</th>
                          <td>{formatCurrency(calculationData.interestEarned)}</td>
                        </tr>
                        <tr>
                          <th scope="col">Maturity Amount:</th>
                          <td>{formatCurrency(calculationData.maturityAmount)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button className="btn btn-success" onClick={exportToExcel}>Export to Excel</button>
                </div>
              )}
              <div className="container">
  <div className="row" style={{ marginTop: '50px' }}>
    <hr />
    <h3 className="text-center">About Sukanya Samriddhi Yojana Calculator</h3>
    <p>
      The Sukanya Samriddhi Yojana (SSY) Calculator is a reliable tool designed to help users estimate the returns on their investments under the Sukanya Samriddhi Yojana scheme. 
      This government-backed savings scheme aims to secure the financial future of a girl child by offering attractive returns and tax benefits. 
      The calculator provides an accurate estimate of the maturity amount and interest earned, helping you plan for your child's higher education or marriage expenses.
    </p>

    <h4>How the Calculator Works</h4>
    <p>
      The Sukanya Samriddhi Yojana Calculator simplifies the complex calculations of interest compounding, enabling parents to focus on financial planning. Based on the input values provided, the calculator estimates:
      <ul>
        <li><strong>Maturity Amount:</strong> The total amount receivable upon completion of the scheme tenure, including all deposits and compounded interest.</li>
        <li><strong>Interest Earned:</strong> The total interest accrued over the investment period.</li>
        <li><strong>Total Deposits:</strong> The sum of all annual contributions made under the scheme.</li>
      </ul>
    </p>

    <h4>Why Choose Sukanya Samriddhi Yojana?</h4>
    <ul>
      <li><strong>Government-Backed Security:</strong> SSY is a safe investment option backed by the Government of India.</li>
      <li><strong>High Interest Rates:</strong> Offers higher interest rates compared to most other savings schemes.</li>
      <li><strong>Tax Benefits:</strong> Provides tax exemptions under Section 80C of the Income Tax Act.</li>
      <li><strong>Long-Term Savings:</strong> Ensures disciplined saving habits with a lock-in period until the girl child turns 21 years old.</li>
      <li><strong>Flexible Contributions:</strong> Minimum deposit of ₹250 per year and a maximum of ₹1.5 lakh per year.</li>
    </ul>

    <h4>Input Parameters</h4>
    <p>
      To calculate accurately, the Sukanya Samriddhi Yojana Calculator requires the following inputs:
      <ul>
        <li><strong>Annual Deposit Amount (₹):</strong> The amount you wish to contribute each year.</li>
        <li><strong>Tenure:</strong> Until the girl child reaches the age of 21 or for 15 years of deposits.</li>
        <li><strong>Interest Rate:</strong> The current annual interest rate (updated periodically by the government).</li>
      </ul>
    </p>

    <h4>Features of the Sukanya Samriddhi Yojana Calculator</h4>
    <ul>
      <li><strong>Simple and User-Friendly Interface:</strong> Easily calculate returns by entering a few details.</li>
      <li><strong>Real-Time Results:</strong> Instant calculations based on the latest interest rates.</li>
      <li><strong>Detailed Breakdown:</strong> View the maturity amount and interest earned over time.</li>
      <li><strong>Customizable Inputs:</strong> Experiment with different deposit amounts to plan effectively.</li>
      <li><strong>Graphical Representation:</strong> Visualize the growth of your savings over the tenure.</li>
    </ul>

    <h4>Maturity and Interest Calculation Formula</h4>
    <p>
      The calculator uses the compound interest formula to estimate the maturity amount:
      <br />
      <code>A = P × (1 + r/n)^(n × t)</code>
      <br />
      Where:
      <ul>
        <li><strong>A:</strong> Maturity amount</li>
        <li><strong>P:</strong> Annual deposit</li>
        <li><strong>r:</strong> Annual interest rate (in decimal)</li>
        <li><strong>n:</strong> Compounding frequency (1 for annual compounding)</li>
        <li><strong>t:</strong> Time (tenure in years)</li>
      </ul>
    </p>

    <h4>Example Calculation</h4>
    <p>
      <strong>Annual Deposit:</strong> ₹50,000 <br />
      <strong>Tenure:</strong> 15 years (deposits), maturity at 21 years <br />
      <strong>Interest Rate:</strong> 7.6% per annum (compounded annually) <br />
      <strong>Formula:</strong> A = 50,000 × (1 + 0.076)^21 <br />
      <strong>Result:</strong> A = ₹22,50,000 (approx.) <br />
      <strong>Interest Earned:</strong> ₹14,00,000 (approx.)
    </p>

    <h4>Benefits of Using the Sukanya Samriddhi Yojana Calculator</h4>
    <ul>
      <li><strong>Financial Planning:</strong> Helps you determine the ideal deposit amount to meet your financial goals for your child.</li>
      <li><strong>Transparency:</strong> Offers a clear view of the scheme's long-term benefits.</li>
      <li><strong>Compare Scenarios:</strong> Experiment with different inputs to optimize your investment strategy.</li>
      <li><strong>Exportable Results:</strong> Download or share your calculations for record-keeping or further analysis.</li>
    </ul>

    <h4>Additional Features of Sukanya Samriddhi Yojana</h4>
    <ul>
      <li><strong>Partial Withdrawals:</strong> Up to 50% withdrawal allowed for higher education after the girl turns 18.</li>
      <li><strong>Flexibility:</strong> Contributions can be made online or offline at any post office or authorized bank branch.</li>
      <li><strong>Extended Tenure:</strong> The scheme matures when the girl turns 21 years old, ensuring long-term savings.</li>
      <li><strong>Accessibility:</strong> Available across India, catering to both rural and urban populations.</li>
    </ul>

    <h4>FAQs About Sukanya Samriddhi Yojana Calculator</h4>
    <ul>
      <li><strong>Can I invest more than ₹1.5 lakh in a year?</strong> No, the maximum annual contribution is capped at ₹1.5 lakh.</li>
      <li><strong>Can I withdraw funds before maturity?</strong> Partial withdrawals are allowed for higher education after the girl turns 18.</li>
      <li><strong>Is the maturity amount taxable?</strong> No, the maturity amount is completely tax-free under Section 80C.</li>
      <li><strong>What happens if I miss a contribution?</strong> A penalty of ₹50 per year is charged, and the account can be revived.</li>
    </ul>
  </div>
</div>

            </div>
          </div>
          <div className="col-md-3">
            <Sidebar />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SukanyaSamriddhiCalculator;
