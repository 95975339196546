import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Layout from '../Component/Layout';
import Sidebar from '../Pages/Sidebar';

const NscCalculator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [principal, setPrincipal] = useState('');
  const [interestRate, setInterestRate] = useState('7.7'); // Default NSC interest rate
  const [investmentTerm, setInvestmentTerm] = useState('5'); // Default term in years
  const [calculationData, setCalculationData] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const p = parseFloat(principal);
    const rate = parseFloat(interestRate) / 100;
    const term = parseInt(investmentTerm);

    // NSC calculation (compound interest annually)
    const maturityAmount = p * Math.pow(1 + rate, term);
    const interestEarned = maturityAmount - p;

    setCalculationData({
      maturityAmount: maturityAmount.toFixed(2),
      interestEarned: interestEarned.toFixed(2),
    });
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([
      {
        'Principal Amount:': principal,
        'Interest Rate:': `${interestRate}%`,
        'Term (Years):': investmentTerm,
        'Interest Earned:': calculationData.interestEarned,
        'Maturity Amount:': calculationData.maturityAmount,
      },
    ]);

    XLSX.utils.book_append_sheet(wb, ws, 'NSCCalculatorData');
    XLSX.writeFile(wb, 'NSC_Calculator_Data.xlsx');
  };

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter.format(amount);
  };

  return (
    <Layout
    pageTitle="Post Office NSC Calculator | PostOfficeCalculators"
    metaDescription="Calculate returns on your Post Office NSC investment with our easy-to-use NSC Calculator."
    metaKeywords="Post Office NSC Calculator, NSC returns, Post Office savings, investment calculator"
    canonicalUrl="https://postofficecalculators.com/post-office-nsc-calculator"
    robotsContent="index, follow"
    ogTitle="Post Office NSC Calculator | PostOfficeCalculators"
    ogDescription="Use our Post Office NSC Calculator to calculate your returns from the National Savings Certificate."
    ogUrl="https://postofficecalculators.com/post-office-nsc-calculator"
    ogImage="https://postofficecalculators.com/img/logo.png"
    schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://postofficecalculators.com/post-office-nsc-calculator",
        "name": "Post Office NSC Calculator",
        "description": "Calculate your returns from the Post Office National Savings Certificate with our easy-to-use calculator.",
        "publisher": {
            "@type": "Organization",
            "name": "PostOfficeCalculators",
            "logo": {
                "@type": "ImageObject",
                "url": "https://postofficecalculators.com/img/logo.png"
            }
        }
    }}
>

      <br />
      

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-8 main-section">
            <div className="container">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/all-tools" style={{ textDecoration: 'none', color: 'black' }}>All Tools</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">NSC Calculator</li>
                </ol>
              </nav>

              <form onSubmit={handleSubmit}>
                <h1 className="text-center">Post Office NSC Calculator</h1>
                <p className="text-center">Estimate the maturity value of your National Savings Certificate (NSC) account.</p>
                <br />
                <div className="container text-center">
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="Principal" className="form-label label">
                        <b>Principal Amount (INR): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={principal}
                        className="form-control"
                        onChange={(e) => setPrincipal(e.target.value)}
                        placeholder="Enter Principal Amount"
                        required
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="InterestRate" className="form-label label">
                        <b>Interest Rate (Annual %): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={interestRate}
                        className="form-control"
                        onChange={(e) => setInterestRate(e.target.value)}
                        placeholder="Enter Interest Rate"
                        required
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="Term" className="form-label label">
                        <b>Investment Term (Years): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={investmentTerm}
                        className="form-control"
                        onChange={(e) => setInvestmentTerm(e.target.value)}
                        placeholder="Enter Investment Term"
                        required
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-md-12">
                      <button type="submit" className="btn btn-success">Calculate</button>
                    </div>
                  </div>
                </div>
              </form>

              {calculationData && (
                <div className="container">
                  <hr />
                  <h5>Summary :</h5>
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th scope="col">Principal Amount:</th>
                          <td>{formatCurrency(principal)}</td>
                        </tr>
                        <tr>
                          <th scope="col">Interest Earned:</th>
                          <td>{formatCurrency(calculationData.interestEarned)}</td>
                        </tr>
                        <tr>
                          <th scope="col">Maturity Amount:</th>
                          <td>{formatCurrency(calculationData.maturityAmount)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button className="btn btn-success" onClick={exportToExcel}>Export to Excel</button>
                </div>
              )}
              <div className="container">
  <div className="row" style={{ marginTop: '50px' }}>
    <hr />
    <h3 className="text-center">About Post Office NSC Calculator</h3>
    <p>
      The Post Office NSC (National Savings Certificate) Calculator is a user-friendly tool designed to help individuals estimate their investment growth under the National Savings Certificate scheme. 
      This secure, government-backed savings instrument offers guaranteed returns and is an excellent option for risk-averse investors looking to achieve long-term financial goals with tax benefits.
    </p>

    <h4>How the Calculator Works</h4>
    <p>
      The Post Office NSC Calculator simplifies the process of calculating the interest earned and maturity value on your investments. Based on the inputs provided, it estimates:
      <ul>
        <li><strong>Maturity Amount:</strong> The total sum receivable at the end of the tenure, including the principal and compounded interest.</li>
        <li><strong>Interest Earned:</strong> The total interest accrued over the investment period.</li>
        <li><strong>Total Deposits:</strong> The amount invested in the NSC scheme.</li>
      </ul>
    </p>

    <h4>Why Choose Post Office NSC?</h4>
    <ul>
      <li><strong>Government-Backed Security:</strong> NSC is a risk-free savings option supported by the Government of India.</li>
      <li><strong>Fixed Returns:</strong> Earn a fixed interest rate, revised periodically by the government.</li>
      <li><strong>Tax Benefits:</strong> Enjoy tax deductions under Section 80C of the Income Tax Act.</li>
      <li><strong>Flexible Investment:</strong> Start with as little as ₹1,000, with no upper limit.</li>
      <li><strong>Compounding Benefits:</strong> Interest is compounded annually and paid at maturity.</li>
    </ul>

    <h4>Input Parameters</h4>
    <p>
      To calculate returns, the NSC Calculator requires the following inputs:
      <ul>
        <li><strong>Investment Amount (₹):</strong> The principal amount you wish to invest.</li>
        <li><strong>Tenure:</strong> The fixed maturity period of 5 years.</li>
        <li><strong>Interest Rate:</strong> The current annual interest rate (updated quarterly by the government).</li>
      </ul>
    </p>

    <h4>Features of the Post Office NSC Calculator</h4>
    <ul>
      <li><strong>Easy to Use:</strong> Simple interface for quick and accurate calculations.</li>
      <li><strong>Real-Time Results:</strong> Instant estimates based on the latest interest rates.</li>
      <li><strong>Comprehensive Breakdown:</strong> Displays the maturity amount and total interest earned.</li>
      <li><strong>Customizable Inputs:</strong> Experiment with different investment amounts to plan your finances effectively.</li>
      <li><strong>Graphical Insights:</strong> Visualize the growth of your investment over time.</li>
    </ul>

    <h4>Maturity and Interest Calculation Formula</h4>
    <p>
      The calculator uses the compound interest formula to determine the maturity amount:
      <br />
      <code>A = P × (1 + r/n)^(n × t)</code>
      <br />
      Where:
      <ul>
        <li><strong>A:</strong> Maturity amount</li>
        <li><strong>P:</strong> Principal amount (investment)</li>
        <li><strong>r:</strong> Annual interest rate (in decimal)</li>
        <li><strong>n:</strong> Compounding frequency (1 for annual compounding)</li>
        <li><strong>t:</strong> Tenure (in years)</li>
      </ul>
    </p>

    <h4>Example Calculation</h4>
    <p>
      <strong>Investment Amount:</strong> ₹1,00,000 <br />
      <strong>Tenure:</strong> 5 years <br />
      <strong>Interest Rate:</strong> 7.7% per annum (compounded annually) <br />
      <strong>Formula:</strong> A = 1,00,000 × (1 + 0.077)^5 <br />
      <strong>Result:</strong> A = ₹1,45,000 (approx.) <br />
      <strong>Interest Earned:</strong> ₹45,000 (approx.)
    </p>

    <h4>Benefits of Using the Post Office NSC Calculator</h4>
    <ul>
      <li><strong>Effective Planning:</strong> Helps you forecast returns and align investments with your goals.</li>
      <li><strong>Transparency:</strong> Provides a clear breakdown of principal, interest, and maturity value.</li>
      <li><strong>Comparison Tool:</strong> Compare scenarios by adjusting inputs to find the most beneficial strategy.</li>
      <li><strong>Save Time:</strong> Eliminates manual calculations with instant results.</li>
    </ul>

    <h4>Additional Features of Post Office NSC</h4>
    <ul>
      <li><strong>Auto-Renewal:</strong> Option to reinvest the maturity amount for another term.</li>
      <li><strong>Loan Facility:</strong> NSC certificates can be used as collateral for loans.</li>
      <li><strong>Nomination Option:</strong> Nominate family members for hassle-free claim processing.</li>
      <li><strong>Wide Reach:</strong> Available at all post offices across India.</li>
    </ul>

    <h4>FAQs About Post Office NSC Calculator</h4>
    <ul>
      <li><strong>Is the interest taxable?</strong> Yes, the interest earned is taxable, but it can be reinvested to claim tax benefits.</li>
      <li><strong>Can I withdraw before maturity?</strong> Premature withdrawal is not allowed, except in specific cases like the death of the holder.</li>
      <li><strong>Is there a minimum investment amount?</strong> Yes, the minimum investment is ₹1,000.</li>
      <li><strong>Are NSC investments safe?</strong> Yes, NSC is a government-backed scheme offering assured returns.</li>
    </ul>
  </div>
</div>

            </div>
          </div>
          <div className="col-md-3">
            <Sidebar />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NscCalculator;
