import React, { useEffect } from 'react';
import Layout from '../Component/Layout';
import { Link } from 'react-router-dom';

const Disclaimer = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout
            pageTitle="Disclaimer | PostOfficeCalculators"
            metaDescription="Read the disclaimer for PostOfficeCalculators to understand the terms of use of the information provided on our website."
            metaKeywords="disclaimer, terms of use, legal disclaimer, PostOfficeCalculators"
            canonicalUrl="https://postofficecalculators.com/disclaimer"
            robotsContent="index, follow"
            ogTitle="Disclaimer | PostOfficeCalculators"
            ogDescription="Understand the terms of use for the information available on PostOfficeCalculators."
            ogUrl="https://postofficecalculators.com/disclaimer"
            ogImage="https://postofficecalculators.com/img/logo.png"
            schemaData={{
                "@context": "https://schema.org",
                "@type": "WebPage",
                "url": "https://postofficecalculators.com/disclaimer",
                "name": "Disclaimer",
                "description": "Understand the terms of use for the information provided on PostOfficeCalculators.",
                "publisher": {
                    "@type": "Organization",
                    "name": "PostOfficeCalculators",
                    "logo": {
                        "@type": "ImageObject",
                        "url": "https://postofficecalculators.com/img/logo.png"
                    }
                }
            }}
        >
            <div className="container-fluid py-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="d-flex justify-content-center">
                    <div
                        className="card shadow-lg mt-4"
                        style={{
                            backgroundColor: '#e0e0e0',
                            borderRadius: '15px',
                            maxWidth: '85%',
                            color: '#333333',
                        }}
                    >
                        <div className="card-body p-5">
                            <header className="text-center mb-4">
                                <h1 className="display-4" style={{ color: '#343a40', fontWeight: 'bold' }}>Disclaimer</h1>
                                <p className="lead" style={{ color: '#495057' }}>
                                    Understand the terms of use for the information available on PostOfficeCalculators.
                                </p>
                            </header>

                            {/* Sections */}
                            <section className="mb-5">
                                <h2 className="h4 text-dark" style={{ fontWeight: 'bold' }}>Interpretation</h2>
                                <p>
                                    The words of which the initial letter is capitalized have meanings defined under the following conditions. 
                                    The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
                                </p>
                            </section>

                            <section className="mb-5">
                                <h2 className="h4 text-dark" style={{ fontWeight: 'bold' }}>Definitions</h2>
                                <p>
                                    <strong>Company:</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Disclaimer) refers to PostOfficeCalculators.
                                </p>
                                <p>
                                    <strong>Service:</strong> Refers to the Website.
                                </p>
                                <p>
                                    <strong>You:</strong> Means the individual accessing the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                                </p>
                                <p>
                                    <strong>Website:</strong> Refers to PostOfficeCalculators, accessible from <a href="https://postofficecalculators.com" target="_blank" rel="noopener noreferrer" style={{ color: '#17a2b8', textDecoration: 'underline' }}>postofficecalculators.com</a>.
                                </p>
                            </section>

                            <section className="mb-5">
                                <h2 className="h4 text-dark" style={{ fontWeight: 'bold' }}>Disclaimer</h2>
                                <p>
                                    The information contained on the Service is for general information purposes only.
                                </p>
                                <p>
                                    The Company assumes no responsibility for errors or omissions in the contents of the Service.
                                </p>
                                <p>
                                    In no event shall the Company be liable for any special, direct, indirect, consequential, or incidental damages 
                                    or any damages whatsoever, whether in an action of contract, negligence, or other tort, arising out of or in 
                                    connection with the use of the Service or the contents of the Service. 
                                </p>
                                <p>
                                    The Company reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice.
                                </p>
                            </section>

                            <section className="mb-5">
                                <h2 className="h4 text-dark" style={{ fontWeight: 'bold' }}>External Links Disclaimer</h2>
                                <p>
                                    The Service may contain links to external websites that are not provided or maintained by or in any way affiliated with the Company.
                                </p>
                                <p>
                                    Please note that the Company does not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.
                                </p>
                            </section>

                            <section className="mb-5">
                                <h2 className="h4 text-dark" style={{ fontWeight: 'bold' }}>Errors and Omissions Disclaimer</h2>
                                <p>
                                    The information given by the Service is for general guidance on matters of interest only. Even if the Company takes 
                                    every precaution to ensure that the content of the Service is both current and accurate, errors can occur. 
                                </p>
                                <p>
                                    The Company is not responsible for any errors or omissions, or for the results obtained from the use of this information.
                                </p>
                            </section>

                            <section className="mb-5">
                                <h2 className="h4 text-dark" style={{ fontWeight: 'bold' }}>Fair Use Disclaimer</h2>
                                <p>
                                    The Company may use copyrighted material which has not always been specifically authorized by the copyright owner. 
                                    The Company believes this constitutes "fair use" as provided for in section 107 of the United States Copyright law.
                                </p>
                                <p>
                                    If You wish to use copyrighted material from the Service for purposes beyond fair use, You must obtain permission from the copyright owner.
                                </p>
                            </section>

                            <section className="mb-5">
                                <h2 className="h4 text-dark" style={{ fontWeight: 'bold' }}>No Responsibility Disclaimer</h2>
                                <p>
                                    The information on the Service is provided with the understanding that the Company is not engaged in rendering legal, 
                                    accounting, tax, or other professional advice. It should not be used as a substitute for professional advice.
                                </p>
                            </section>

                            

                            <section className="mb-5">
                                <h2 className="h4 text-dark" style={{ fontWeight: 'bold' }}>Advertisements</h2>
                                <p>
                                    The website displays advertisements, including but not limited to Google Ads. These ads are provided by third parties. 
                                    We encourage users to carefully evaluate any offers presented through these ads.
                                </p>
                            </section>

                            <section className="mb-5">
                                <h2 className="h4 text-dark" style={{ fontWeight: 'bold' }}>Contact Us</h2>
                                <p>If you have any questions about this Disclaimer, You can contact Us:</p>
                                <ul className="list-group">
                                    <li className="list-group-item">
                                        Email: support@postofficecalculators.com
                                    </li>
                                    <li className="list-group-item">
                                        Website Contact Page: <Link
                                            to="/contact-us"
                                            style={{ color: '#17a2b8', textDecoration: 'underline' }}
                                        >
                                            Contact Us
                                        </Link>
                                    </li>
                                </ul>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Disclaimer;
