import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Layout from '../Component/Layout';
import Sidebar from '../Pages/Sidebar';

const SeniorCitizenCalculator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [principal, setPrincipal] = useState('');
  const [interestRate, setInterestRate] = useState('7.4'); // Default interest rate for SCSS (as per recent rates)
  const [investmentTerm, setInvestmentTerm] = useState('');
  const [calculationData, setCalculationData] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const P = parseFloat(principal);
    const rate = parseFloat(interestRate);
    const term = parseInt(investmentTerm);

    // Simple interest calculation for SCSS
    const interest = (P * rate * term) / 100;
    const totalAmount = P + interest;

    setCalculationData({
      totalAmount: totalAmount.toFixed(2),
      interest: interest.toFixed(2),
      years: Array.from({ length: term }, (_, i) => i + 1),
    });
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([{
      'Principal Amount:': principal,
      'Total Interest:': calculationData.interest,
      'Total Amount:': calculationData.totalAmount,
    }]);

    XLSX.utils.book_append_sheet(wb, ws, 'SCSSData');
    XLSX.writeFile(wb, 'Senior_Citizen_Savings_Scheme_Calculator_Data.xlsx');
  };

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter.format(amount);
  };

  return (
    <Layout
      pageTitle="Senior Citizen Savings Scheme Calculator | SCSS Calculator"
      metaDescription="Calculate returns on Senior Citizen Saving Scheme (SCSS) investment with our SCSS calculator."
      metaKeywords="Senior Citizen Savings Scheme, SCSS Calculator, Post Office SCSS"
      canonicalUrl="https://postofficecalculators.com/post-office-scss-calculator"
      robotsContent="index, follow"
      ogTitle="Senior Citizen Savings Scheme Calculator | SCSS Calculator"
      ogDescription="Estimate returns on your Senior Citizen Saving Scheme investment with our SCSS calculator."
      ogUrl="https://postofficecalculators.com/post-office-scss-calculator"
      ogImage="https://postofficecalculators.com/img/logo.png"
      schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://postofficecalculators.com/post-office-scss-calculator",
        "name": "Senior Citizen Savings Scheme Calculator",
        "description": "Calculate returns on Senior Citizen Savings Scheme (SCSS) using our calculator.",
        "publisher": {
          "@type": "Organization",
          "name": "PostOfficeCalculators",
          "logo": {
            "@type": "ImageObject",
            "url": "https://postofficecalculators.com/img/logo.png"
          }
        }
      }}
    >
      <br />
      

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-8 main-section">
            <div className="container">
              <div className="header-ads"></div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/all-tools" style={{ textDecoration: 'none', color: 'black' }}>All Tools</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Senior Citizen Calculator</li>
                </ol>
              </nav>

              <form onSubmit={handleSubmit}>
                <h1 className="text-center">Senior Citizen Savings Scheme Calculator</h1>
                <p className="text-center">Estimate the returns on your Senior Citizen Savings Scheme (SCSS) investment.</p>
                <br />
                <div className="container text-center">
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="Principal" className="form-label label">
                        <b>Principal Amount (INR): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={principal}
                        className="form-control"
                        onChange={(e) => setPrincipal(e.target.value)}
                        placeholder="Enter Principal Amount"
                        required
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="InterestRate" className="form-label label">
                        <b>Interest Rate (Annual %): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={interestRate}
                        className="form-control"
                        onChange={(e) => setInterestRate(e.target.value)}
                        placeholder="Enter Interest Rate"
                        required
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="Term" className="form-label label">
                        <b>Investment Term (Years): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={investmentTerm}
                        className="form-control"
                        onChange={(e) => setInvestmentTerm(e.target.value)}
                        placeholder="Enter Investment Term"
                        required
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-md-12">
                      <button type="submit" className="btn btn-success">Calculate</button>
                    </div>
                  </div>
                </div>
              </form>

              {calculationData && (
                <div className="container">
                  <hr />
                  <h5>Summary :</h5>
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th scope="col">Total Amount:</th>
                          <td>{formatCurrency(calculationData.totalAmount)}</td>
                        </tr>
                        <tr>
                          <th scope="col">Total Interest:</th>
                          <td>{formatCurrency(calculationData.interest)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button className="btn btn-success" onClick={exportToExcel}>Export to Excel</button>
                </div>
              )}
              <div className="container">
  <div className="row" style={{ marginTop: '50px' }}>
    <hr />
    <h3 className="text-center">About Senior Citizen Saving Scheme Post Office Calculator</h3>
    <p>
      The Senior Citizen Saving Scheme (SCSS) Post Office Calculator is a reliable tool designed to help senior citizens estimate the returns on their SCSS investments with the Indian Post Office. 
      This scheme is a government-backed savings option specifically designed for senior citizens, providing a safe and lucrative investment opportunity with regular income. 
      The calculator offers accurate estimates of the quarterly interest income and maturity amount, helping seniors in financial planning and securing their future.
    </p>

    <h4>How the Calculator Works</h4>
    <p>
      The Senior Citizen Saving Scheme Calculator simplifies the calculation of interest earned and the maturity amount, based on the inputs provided. The calculator estimates:
      <ul>
        <li><strong>Maturity Amount:</strong> The total amount you will receive at the end of the SCSS tenure, including the principal and interest earned over time.</li>
        <li><strong>Interest Earned:</strong> The total interest generated over the deposit period, helping you track your income.</li>
        <li><strong>Total Investment:</strong> The principal amount you invested initially.</li>
      </ul>
    </p>

    <h4>Why Choose Senior Citizen Saving Scheme (SCSS)?</h4>
    <ul>
      <li><strong>Government-Backed Security:</strong> SCSS is a safe investment option, fully backed by the Government of India.</li>
      <li><strong>Quarterly Interest Payments:</strong> SCSS pays interest on a quarterly basis, offering regular income to senior citizens.</li>
      <li><strong>Attractive Interest Rates:</strong> SCSS offers higher interest rates compared to regular savings accounts and many other investment options.</li>
      <li><strong>Tax Benefits:</strong> Interest earned on SCSS is taxable, but investments up to ₹1.5 lakh qualify for tax deductions under Section 80C.</li>
      <li><strong>Guaranteed Returns:</strong> Fixed interest rates ensure predictable returns, providing security for retirees.</li>
    </ul>

    <h4>Input Parameters</h4>
    <p>
      To provide accurate results, the Senior Citizen Saving Scheme Post Office Calculator requires the following inputs:
      <ul>
        <li><strong>Deposit Amount (₹):</strong> The principal amount you wish to invest in the scheme.</li>
        <li><strong>Tenure:</strong> Choose between 5 years, which is the standard tenure for SCSS.</li>
        <li><strong>Interest Rate:</strong> The current annual interest rate offered for SCSS (updated as per government notifications).</li>
      </ul>
    </p>

    <h4>Features of the Senior Citizen Saving Scheme Post Office Calculator</h4>
    <ul>
      <li><strong>Simple and User-Friendly Interface:</strong> Easily calculate interest and maturity amounts by entering a few details.</li>
      <li><strong>Real-Time Results:</strong> Instant calculations based on the latest interest rates.</li>
      <li><strong>Detailed Breakdown:</strong> View the maturity amount and interest earned over time.</li>
      <li><strong>Customizable Inputs:</strong> Experiment with different deposit amounts to plan effectively for your retirement.</li>
      <li><strong>Graphical Representation:</strong> Visualize how your investment grows over the chosen tenure.</li>
    </ul>

    <h4>Maturity and Interest Calculation Formula</h4>
    <p>
      The calculator uses the simple interest formula to estimate the interest earned and maturity amount for SCSS:
      <br />
      <code>I = P × r × t</code> for calculating interest, and <br />
      <code>M = P + I</code> for the maturity amount, where:
      <ul>
        <li><strong>M:</strong> Maturity amount (Principal + Interest)</li>
        <li><strong>P:</strong> Principal amount</li>
        <li><strong>r:</strong> Annual interest rate (in decimal)</li>
        <li><strong>t:</strong> Time (tenure in years)</li>
      </ul>
    </p>

    <h4>Example Calculation</h4>
    <p>
      <strong>Deposit Amount:</strong> ₹5,00,000 <br />
      <strong>Tenure:</strong> 5 years <br />
      <strong>Interest Rate:</strong> 8.0% per annum (quarterly compounding) <br />
      <strong>Formula:</strong> I = 5,00,000 × 0.08 × 5 = ₹2,00,000 <br />
      <strong>Result:</strong> M = ₹5,00,000 + ₹2,00,000 = ₹7,00,000 <br />
      <strong>Interest Earned:</strong> ₹2,00,000
    </p>

    <h4>Benefits of Using the SCSS Post Office Calculator</h4>
    <ul>
      <li><strong>Financial Planning:</strong> Helps you determine the best deposit amount and interest rate to meet your financial needs.</li>
      <li><strong>Transparency:</strong> Provides a clear breakdown of how interest is calculated and compounds over time.</li>
      <li><strong>Compare Scenarios:</strong> Experiment with different deposit amounts to see how they impact your returns.</li>
      <li><strong>Exportable Results:</strong> Download or share your calculations for future reference.</li>
    </ul>

    <h4>Additional Features of Senior Citizen Saving Scheme (SCSS)</h4>
    <ul>
      <li><strong>Liquidity:</strong> Premature withdrawals are allowed, but penalties may apply.</li>
      <li><strong>Nomination Facility:</strong> Assign a nominee to ensure smooth transfer of funds in case of unforeseen events.</li>
      <li><strong>Renewal Option:</strong> SCSS accounts can be renewed after maturity for continued returns.</li>
      <li><strong>High Accessibility:</strong> Available across all Post Office branches, ensuring convenience for senior citizens across the country.</li>
    </ul>

    <h4>FAQs About Senior Citizen Saving Scheme Calculator</h4>
    <ul>
      <li><strong>Can I withdraw my SCSS prematurely?</strong> Yes, premature withdrawals are allowed, but a penalty may apply depending on the time of withdrawal.</li>
      <li><strong>Is there a minimum deposit amount?</strong> Yes, the minimum deposit amount is ₹1,000.</li>
      <li><strong>What happens if I miss renewing my SCSS on maturity?</strong> If not renewed, your account will continue to earn interest at the prevailing savings account rate.</li>
      <li><strong>Are SCSS returns taxable?</strong> Yes, interest earned on SCSS is taxable, but investments up to ₹1.5 lakh qualify for tax deductions under Section 80C.</li>
    </ul>
  </div>
</div>
              
            </div>
          </div>
          <div className="col-md-3">
            <Sidebar />
        </div>
        </div>
      </div>
    </Layout>
  );
};

export default SeniorCitizenCalculator;
