import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Layout from '../Component/Layout';
import Sidebar from '../Pages/Sidebar';

const PostOfficeSIPCalculator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [monthlyContribution, setMonthlyContribution] = useState('');
  const [interestRate, setInterestRate] = useState('7'); // Default annual interest rate
  const [investmentTerm, setInvestmentTerm] = useState('');
  const [calculationData, setCalculationData] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    const contribution = parseFloat(monthlyContribution);
    const rate = parseFloat(interestRate) / 12 / 100; // Monthly interest rate
    const term = parseInt(investmentTerm) * 12; // Term in months

    // SIP maturity calculation
    const maturityValue =
      contribution * ((Math.pow(1 + rate, term) - 1) / rate) * (1 + rate);

    const totalInvestment = contribution * term;
    const totalInterest = maturityValue - totalInvestment;

    setCalculationData({
      maturityValue: maturityValue.toFixed(2),
      totalInvestment: totalInvestment.toFixed(2),
      totalInterest: totalInterest.toFixed(2),
      years: Array.from({ length: term / 12 }, (_, i) => i + 1),
    });
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([{
      'Monthly Contribution (INR)': monthlyContribution,
      'Total Investment (INR)': calculationData.totalInvestment,
      'Total Interest (INR)': calculationData.totalInterest,
      'Maturity Value (INR)': calculationData.maturityValue,
    }]);

    XLSX.utils.book_append_sheet(wb, ws, 'SIP_Calculator_Data');
    XLSX.writeFile(wb, 'Post_Office_SIP_Calculator_Data.xlsx');
  };

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter.format(amount);
  };

  return (
    <Layout
      pageTitle="Post Office SIP Calculator | PostOfficeCalculators"
      metaDescription="Calculate SIP returns on Post Office savings schemes with our SIP Calculator."
      metaKeywords="Post Office SIP Calculator, Post Office SIP returns, savings scheme calculator"
      canonicalUrl="https://postofficecalculators.com/post-office-sip-calculator"
      robotsContent="index, follow"
      ogTitle="Post Office SIP Calculator | PostOfficeCalculators"
      ogDescription="Calculate SIP returns on Post Office savings schemes with our SIP Calculator."
      ogUrl="https://postofficecalculators.com/post-office-sip-calculator"
      ogImage="https://postofficecalculators.com/img/logo.png"
      schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://postofficecalculators.com/post-office-sip-calculator",
        "name": "Post Office SIP Calculator",
        "description": "Calculate SIP returns on Post Office savings schemes with our SIP Calculator.",
        "publisher": {
          "@type": "Organization",
          "name": "PostOfficeCalculators",
          "logo": {
            "@type": "ImageObject",
            "url": "https://postofficecalculators.com/img/logo.png"
          }
        }
      }}
    >
      <br />
      
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-8 main-section">
            <div className="container">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/all-tools" style={{ textDecoration: 'none', color: 'black' }}>All Tools</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Post Office SIP Calculator</li>
                </ol>
              </nav>

              <form onSubmit={handleSubmit}>
                <h1 className="text-center">Post Office SIP Calculator</h1>
                <p className="text-center">Estimate the returns on your SIP investments in Post Office schemes.</p>
                <br />
                <div className="container text-center">
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="MonthlyContribution" className="form-label label">
                        <b>Monthly Contribution (INR): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={monthlyContribution}
                        className="form-control"
                        onChange={(e) => setMonthlyContribution(e.target.value)}
                        placeholder="Enter Monthly Contribution"
                        required
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="InterestRate" className="form-label label">
                        <b>Interest Rate (Annual %): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={interestRate}
                        className="form-control"
                        onChange={(e) => setInterestRate(e.target.value)}
                        placeholder="Enter Interest Rate"
                        required
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="Term" className="form-label label">
                        <b>Investment Term (Years): </b><span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={investmentTerm}
                        className="form-control"
                        onChange={(e) => setInvestmentTerm(e.target.value)}
                        placeholder="Enter Investment Term"
                        required
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-md-12">
                      <button type="submit" className="btn btn-success">Calculate</button>
                    </div>
                  </div>
                </div>
              </form>

              {calculationData && (
                <div className="container">
                  <hr />
                  <h5>Summary :</h5>
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th scope="col">Maturity Value:</th>
                          <td>{formatCurrency(calculationData.maturityValue)}</td>
                        </tr>
                        <tr>
                          <th scope="col">Total Investment:</th>
                          <td>{formatCurrency(calculationData.totalInvestment)}</td>
                        </tr>
                        <tr>
                          <th scope="col">Total Interest:</th>
                          <td>{formatCurrency(calculationData.totalInterest)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button className="btn btn-success" onClick={exportToExcel}>Export to Excel</button>
                </div>
              )}
              <div className="container">
  <div className="row" style={{ marginTop: '50px' }}>
    <hr />
    <h3 className="text-center">About Post Office SIP Calculator</h3>
    <p>
      The Post Office SIP (Systematic Investment Plan) Calculator is a comprehensive tool designed to help investors plan their savings and estimate the returns on their investments in the Post Office Recurring Deposit (RD) scheme. 
      This government-backed scheme allows you to grow your wealth systematically with assured returns, making it ideal for risk-averse investors.
    </p>

    <h4>How the Calculator Works</h4>
    <p>
      The Post Office SIP Calculator simplifies the process of estimating returns by calculating:
      <ul>
        <li><strong>Maturity Amount:</strong> The total value of your investment at the end of the tenure, including principal and interest.</li>
        <li><strong>Interest Earned:</strong> The total interest generated over the chosen investment period.</li>
        <li><strong>Monthly Investment:</strong> The amount you choose to invest regularly.</li>
      </ul>
    </p>

    <h4>Why Choose Post Office SIP?</h4>
    <ul>
      <li><strong>Government-Backed Security:</strong> Post Office SIPs are highly secure, being backed by the Government of India.</li>
      <li><strong>Flexible Investment Options:</strong> Start with small monthly contributions as low as ₹100.</li>
      <li><strong>Quarterly Compounding:</strong> Interest is compounded quarterly, leading to better growth over time.</li>
      <li><strong>Easy Accessibility:</strong> Available across all Post Office branches, making it convenient for all investors.</li>
      <li><strong>Guaranteed Returns:</strong> Fixed interest rates provide predictable outcomes for your financial planning.</li>
    </ul>

    <h4>Input Parameters</h4>
    <p>
      To provide accurate results, the Post Office SIP Calculator requires the following inputs:
      <ul>
        <li><strong>Monthly Investment (₹):</strong> The fixed amount you plan to deposit every month.</li>
        <li><strong>Tenure:</strong> Choose from 1, 2, 3, or 5 years as per your financial goals.</li>
        <li><strong>Interest Rate:</strong> The applicable interest rate for the RD scheme (updated quarterly).</li>
      </ul>
    </p>

    <h4>Features of the Post Office SIP Calculator</h4>
    <ul>
      <li><strong>Simple and User-Friendly:</strong> Input a few details to instantly calculate results.</li>
      <li><strong>Real-Time Updates:</strong> Reflects the latest interest rates.</li>
      <li><strong>Breakdown of Results:</strong> Displays maturity amount and interest earned.</li>
      <li><strong>Customizable:</strong> Experiment with different tenures and investment amounts.</li>
      <li><strong>Graphical View:</strong> Visualize the growth of your savings over time.</li>
    </ul>

    <h4>Maturity and Interest Calculation Formula</h4>
    <p>
      The maturity amount is calculated using the formula:
      <br />
      <code>M = P × (1 + r/n)^(n × t)</code>
      <br />
      Where:
      <ul>
        <li><strong>M:</strong> Maturity amount</li>
        <li><strong>P:</strong> Total contributions (monthly investment × number of months)</li>
        <li><strong>r:</strong> Annual interest rate (in decimal)</li>
        <li><strong>n:</strong> Compounding frequency (4 for quarterly compounding)</li>
        <li><strong>t:</strong> Time (tenure in years)</li>
      </ul>
    </p>

    <h4>Example Calculation</h4>
    <p>
      <strong>Monthly Investment:</strong> ₹2,000 <br />
      <strong>Tenure:</strong> 3 years <br />
      <strong>Interest Rate:</strong> 6.8% per annum (compounded quarterly) <br />
      <strong>Formula:</strong> M = Total Contributions + Interest Earned <br />
      <strong>Result:</strong> M = ₹76,236.56 <br />
      <strong>Interest Earned:</strong> ₹8,236.56
    </p>

    <h4>Benefits of Using the Post Office SIP Calculator</h4>
    <ul>
      <li><strong>Better Planning:</strong> Helps in choosing the right monthly contribution to achieve your financial goals.</li>
      <li><strong>Transparency:</strong> Offers a clear breakdown of how interest compounds over time.</li>
      <li><strong>Compare Scenarios:</strong> Experiment with different values to find the best investment strategy.</li>
      <li><strong>Convenience:</strong> Get quick and accurate estimates without manual calculations.</li>
    </ul>

    <h4>Additional Features of Post Office SIP</h4>
    <ul>
      <li><strong>Liquidity:</strong> Allows premature withdrawal, subject to certain conditions.</li>
      <li><strong>Nomination Facility:</strong> Assign a nominee for smooth fund transfer in unforeseen events.</li>
      <li><strong>Guaranteed Growth:</strong> Stable and predictable returns for secure financial planning.</li>
      <li><strong>Low Investment Requirement:</strong> Start with small amounts to build a disciplined savings habit.</li>
    </ul>

    <h4>FAQs About Post Office SIP Calculator</h4>
    <ul>
      <li><strong>Can I modify my monthly contribution?</strong> No, the monthly contribution is fixed for the tenure.</li>
      <li><strong>Is there a penalty for premature withdrawal?</strong> Yes, withdrawals are subject to penalties as per Post Office RD rules.</li>
      <li><strong>What happens if I miss a payment?</strong> You may need to pay a penalty to continue your RD.</li>
      <li><strong>Is the maturity amount taxable?</strong> Yes, the interest earned is taxable under applicable laws.</li>
    </ul>
  </div>
</div>

            </div>
          </div>
          <div className="col-md-3">
            <Sidebar />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PostOfficeSIPCalculator;
