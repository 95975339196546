import React from "react";
import { useEffect } from "react";
import Layout from "../Component/Layout";
import "./Contact.css";

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout
    pageTitle="About Us | PostOfficeCalculators"
    metaDescription="Learn more about PostOfficeCalculators, our mission, and the services we offer."
    metaKeywords="about us, PostOfficeCalculators, mission, services, team"
    canonicalUrl="https://postofficecalculators.com/about-us"
    robotsContent="index, follow"
    ogTitle="About Us | PostOfficeCalculators"
    ogDescription="Discover the story of PostOfficeCalculators, our mission, and the services we offer."
    ogUrl="https://postofficecalculators.com/about-us"
    ogImage="https://postofficecalculators.com/img/logo.png"
    schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://postofficecalculators.com/about-us",
        "name": "About Us",
        "description": "Learn about PostOfficeCalculators, our mission, and the services we provide to help you.",
        "publisher": {
            "@type": "Organization",
            "name": "PostOfficeCalculators",
            "logo": {
                "@type": "ImageObject",
                "url": "https://postofficecalculators.com/img/logo.png"
            }
        }
    }}
>

      {/* Top Banner */}
      <div className="image-aboutus-banner">
        <div className="container">
          <div className="col-md-12 text-center mt-5">
            <h1 className="lg-text text-white">About Us</h1>
            <p className="text-white mt-3">
              At Post Office Calculators, we aim to simplify your financial journey by providing accurate and easy-to-use tools.
            </p>
          </div>
        </div>
      </div>

      <div className="container custom-container mt-5">
        {/* Vision Section */}
        <section className="mb-5 text-center">
          <h2 className="display-5 mb-3 text-dark"><b>Our Vision</b></h2>
          <p className="lead">
            We envision a world where financial calculations are simplified for everyone. Our team is dedicated to providing
            <b> free tools </b> that help individuals understand complex financial scenarios like post office schemes, savings, and
            investments in a clear and intuitive way.
          </p>
          <p>
            By offering a reliable source for calculations, we hope to empower people to make <b>informed decisions</b> regarding their
            financial futures, whether they are saving for retirement or investing in a post office scheme.
          </p>
        </section>

        {/* Developer Section */}
        <section className="row my-4 align-items-center">
          <div className="col-md-6 mb-4">
            <h3 className="h4 mb-3 text-dark">Meet Our Developer: Aman</h3>
            <p>
              <b>Aman</b>, the mastermind behind Post Office Calculators, is a passionate developer with expertise in full-stack
              development. His dedication to creating user-friendly solutions has been the driving force behind our platform's
              success. With proficiency in <b>JavaScript, Python, HTML, and React</b>, Aman constantly strives to innovate and
              improve the tools we provide.
            </p>
          </div>
          <div className="col-md-6">
            <img
              src="./img/developer.jpg"
              alt="Aman - Developer"
              className="img-fluid rounded-circle"
              style={{ width: "100%", maxWidth: "250px", margin: "auto" }}
            />
          </div>
        </section>

        {/* Consultant Section */}
        <section className="row my-4 align-items-center">
          <div className="col-md-6 mb-4">
            <h3 className="h4 mb-3 text-dark">Our Consultant: Roshan </h3>
            <p>
              <b>Roshan</b> is a seasoned expert in financial consultancy, specializing in post office schemes and investment options.
              He ensures that our calculators are not only accurate but also up-to-date with the latest offerings from various
              post office services. Roshan focus is on helping individuals understand the nuances of different financial
              instruments and providing the best solutions to users.
            </p>
          </div>
          <div className="col-md-6">
            <img
              src="./img/consultent.jpg"
              alt="Roshan - Consultant"
              className="img-fluid rounded-circle"
              style={{ width: "100%", maxWidth: "250px", margin: "auto" }}
            />
          </div>
        </section>

        {/* Process Section */}
        <section className="mb-5">
          <h2 className="display-5 mb-3 text-dark text-center"><b>Our Process</b></h2>
          <p className="lead text-center">
            The Post Office Calculators team follows a strict process to ensure that every tool is <b>accurate</b>, <b>reliable</b>, and
            <b> easy to use</b>. Here's how we work:
          </p>
          <ul className="list-unstyled">
            <li className="mb-3">
              <h5 className="font-weight-bold">1. Research:</h5>
              <p>We continuously analyze the latest post office schemes and their details to incorporate into our tools.</p>
            </li>
            <li className="mb-3">
              <h5 className="font-weight-bold">2. Development:</h5>
              <p>Our development team works meticulously to build tools that are simple, intuitive, and serve their purpose.</p>
            </li>
            <li className="mb-3">
              <h5 className="font-weight-bold">3. Testing & Feedback:</h5>
              <p>Before launching any tool, we conduct extensive testing, ensuring that they are both functional and user-friendly.</p>
            </li>
          </ul>
        </section>
        {/* Stay Connected Section */}
        <section className="text-center mt-5">
          <h2 className="display-5 mb-3 text-dark"><b>Stay Connected</b></h2>
          <p className="lead">
            Want to stay updated with the latest post office financial tools, new features, and more?
          </p>
          <a href="/contact-us" className="btn btn-success">
            Contact Us
          </a>
        </section>
      </div>
    </Layout>
  );
}

export default AboutUs;
